import { data } from "jquery";
import React, { useEffect, useState } from "react";
import html2pdf from "html2pdf.js";
import logo from "../images/logo.png";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import db from "./firebaseConfig";
import "./css/paymentSuccess.css";
import { styled, alpha, Box } from "@mui/system";
import SliderUnstyled, {
  sliderUnstyledClasses,
} from "@mui/base/SliderUnstyled";
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  300: "#66B2FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledSlider = styled(SliderUnstyled)(
  ({ theme }) => `
    color: ${theme.palette.mode === "light" ? blue[500] : blue[300]};
    height: 6px;
    width: 100%;
    padding: 16px 0;
    display: inline-block;
    position: relative;
    cursor: pointer;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
  
    &:hover {
      opacity: 1;
    }
  
    &.${sliderUnstyledClasses.disabled} { 
      pointer-events: none;
      cursor: default;
      color: ${theme.palette.mode === "light" ? grey[300] : grey[600]};
      opacity: 0.5;
    }
  
    & .${sliderUnstyledClasses.rail} {
      display: block;
      position: absolute;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background-color: currentColor;
      opacity: 0.4;
    }
  
    & .${sliderUnstyledClasses.track} {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 2px;
      background-color: currentColor;
    }
  
    & .${sliderUnstyledClasses.thumb} {
      position: absolute;
      width: 16px;
      height: 16px;
      margin-left: -6px;
      margin-top: -6px;
      box-sizing: border-box;
      border-radius: 50%;
      outline: 0;
      border: 3px solid currentColor;
      background-color: #fff;
  
      :hover,
      &.${sliderUnstyledClasses.focusVisible} {
        box-shadow: 0 0 0 0.25rem ${alpha(
          theme.palette.mode === "light" ? blue[400] : blue[300],
          0.15
        )};
      }
  
      &.${sliderUnstyledClasses.active} {
        box-shadow: 0 0 0 0.25rem ${alpha(
          theme.palette.mode === "light" ? blue[200] : blue[300],
          0.3
        )};
      }
    }
  
    & .${sliderUnstyledClasses.mark} {
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: currentColor;
      top: 50%;
      opacity: 0.7;
      transform: translateX(-50%);
    }
  
    & .${sliderUnstyledClasses.markActive} {
      background-color: #fff;
    }
  
    & .${sliderUnstyledClasses.valueLabel} {
      font-family: IBM Plex Sans;
      font-size: 14px;
      display: block;
      position: relative;
      top: -1.6em;
      text-align: center;
      transform: translateX(-50%);
    }
  
    & .${sliderUnstyledClasses.markLabel} {
      font-family: IBM Plex Sans;
      font-size: 12px;
      position: absolute;
      top: 20px;
      transform: translateX(-50%);
      margin-top: 8px;
    }
  `
);

const marks = [
  {
    value: 0,
    label: "start",
  },
  {
    value: 50,
    label: "On the way",
  },
  {
    value: 100,
    label: "Completed",
  },
];

function valuetext(value) {
  return `${value}°C`;
}

function Orders() {
  const [orders, setOrders] = useState([]);
  const [lgShow, setLgShow] = useState(false);
  const [showB, setShowB] = useState(false);
  const [indexState, setIndexState] = useState(0);

  useEffect(() => {
    fetchOrder();
  }, []);
  function fetchOrder() {
    setOrders([]);
    db.collection("order_details")
      .where("customer_details.cus_email", "==", localStorage.getItem("email"))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let data = doc.data();
          data.docId = doc.id;
          setOrders((arr) => [...arr, data]);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }
  function invoiceDownload() {
    const element = document.getElementById("pdf-content"); // Provide the ID of the HTML element to convert

    html2pdf()
      .from(element)
      .save(`${orders[indexState].order_detail.invoiceId}.pdf`);
  }
  const toggleShowB = () => setShowB(false);

  function deleteOrd(doc_id) {
    const docRef = db.collection("order_details").doc(doc_id);

    // Delete the document
    docRef
      .delete()
      .then(() => {
        setShowB(true);
        fetchOrder();

        console.log("Document successfully deleted.");
      })
      .catch((error) => {
        console.error("Error deleting document:", error);
      });
  }
  return (
    <div>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12" style={{ backgroundColor: "#d3d3e1" }}>
            <div>
              <div>
                <h2>Welcome!!!, Your Previous and Current Orders here</h2>
              </div>
            </div>
          </div>
        </div>
        {orders.length > 0 ? (
          <div>
            {orders.map((val, index) => (
              <div
                className="row"
                style={{
                  marginTop: "2%",
                  border: "1px solid #bda7a7",
                }}
              >
                <div
                  className="col-md-6 mt-3"
                  // style={{ marginTop: "2%", marginLeft: "2%" }}
                >
                  <h6>
                    <strong>Order Id:</strong> {val.order_detail.orderId}
                  </h6>
                  <h6>
                    <strong>Payment Method:</strong> {val.order_detail.method}
                  </h6>
                  <h6>
                    <strong>order date:</strong> {val.order_detail.order_date}
                  </h6>
                  {/* <Box sx={{ width: 300 }}>
                        <StyledSlider
                          aria-label="Temperature"
                          defaultValue={val.value}
                          getAriaValueText={valuetext}
                          step={null}
                          marks={marks}
                        />
                      </Box> */}
                </div>
                <div
                  // className="prod_img text-end"
                  className="col-md-6 mt-3"
                  // style={{ marginTop: "-10%" }}
                >
                  <h6>
                    <strong>Ship port:</strong> {val.customer_details.cus_port}
                  </h6>
                  <h6>
                    <strong>Amount:</strong> {val.order_detail.order_amount}
                  </h6>
                  <h6>
                    <strong>Order Products:</strong>{" "}
                    {val.product_details.length}
                  </h6>
                </div>
                <div className="col-md-6 d-flex mb-3">
                  <div className="">
                    <button
                      onClick={() => {
                        setIndexState(index);
                        setLgShow(true);
                      }}
                      type="button"
                      className="btn-danger"
                    >
                      Detail view
                    </button>
                  </div>
                  <div style={{ paddingLeft: "15px" }}>
                    <button
                      onClick={() => deleteOrd(val.docId)}
                      className="btn-secondary"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center">No orders Found</p>
        )}
      </div>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Invoice</Modal.Title>
        </Modal.Header>
        {orders.length > 0 ? (
          <Modal.Body>
            <button onClick={() => invoiceDownload()} className="btn-danger">
              Download
            </button>
            {Object.keys(orders[0]).length > 0 ? (
              <div id="pdf-content" class="container">
                <div class="row">
                  {/* <!-- BEGIN INVOICE --> */}
                  <div class="col-xs-12">
                    <div class="grid invoice">
                      <div class="grid-body">
                        <div class="invoice-title">
                          <div class="row">
                            <div class="col-md-12"></div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col-md-8">
                              <h2>
                                invoice
                                <br />
                                <span class="small">
                                  order #
                                  {orders[indexState].order_detail.orderId}
                                </span>
                              </h2>
                            </div>
                            <div class="col-md-4">
                              <img
                                src={logo}
                                alt="invoice-logo"
                                height="40px"
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-md-6">
                            <address>
                              <strong>Billed To:</strong>
                              <br />
                              SEAFARERSCHOICE.
                              <br />
                              116, Ambasamudram main road
                              <br />
                              Alwarkurichi, Tenkasi District - 627 412.
                              <br />
                              <abbr title="Phone"></abbr>(+91) 8807223822
                            </address>
                          </div>
                          <div class="col-md-6 text-right">
                            <address>
                              <strong>Shipped To:</strong>
                              <br />
                              {
                                orders[indexState].customer_details
                                  .receiver_name
                              }
                              <br />
                              {
                                orders[indexState].customer_details
                                  .cus_ship_number
                              }
                              <br />
                              {orders[indexState].customer_details.cus_port}
                              <br />
                              {/* <br />
                          {orders.customer_details.cus_id}
                          <br /> */}
                              <abbr title="Phone"></abbr>
                              {orders[indexState].customer_details.cus_mob}
                            </address>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <address>
                              <strong>Payment Method:</strong>
                              <br />
                              {orders[indexState].order_detail.method}
                              <>
                                {orders[indexState].order_detail.method !==
                                "cod" ? (
                                  <>
                                    <br />
                                    {orders[indexState].order_detail.orderId}
                                    <br />
                                  </>
                                ) : null}
                              </>
                            </address>
                          </div>
                          <div class="col-md-6 text-right">
                            <address>
                              <strong>Order Date:</strong>
                              <br />
                              {orders[indexState].order_detail.order_date}
                            </address>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h3>ORDER SUMMARY</h3>
                            <table class="table table-striped">
                              <thead>
                                <tr class="line">
                                  <td>
                                    <strong>#</strong>
                                  </td>
                                  <td class="text-center">
                                    <strong>PRODUCTS</strong>
                                  </td>
                                  <td class="text-center">
                                    <strong>QUANTITY</strong>
                                  </td>
                                  <td class="text-right">
                                    <strong>PRICE</strong>
                                  </td>
                                  <td class="text-right">
                                    <strong>SUBTOTAL</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {orders[indexState].product_details.map(
                                  (val, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        <strong>
                                          {val.product.product_name}
                                        </strong>
                                      </td>
                                      <td class="text-center">
                                        {val.quantity}
                                      </td>
                                      <td class="text-center">
                                        ₹{" "}
                                        {
                                          val.product.product_highlights
                                            .Price_range
                                        }
                                      </td>
                                      <td class="text-right">
                                        ₹{" "}
                                        {val.quantity *
                                          val.product.product_highlights
                                            .Price_range}
                                      </td>
                                    </tr>
                                  )
                                )}
                                <tr>
                                  <td colspan="3"></td>
                                  <td class="text-right">
                                    <strong>Taxes</strong>
                                  </td>
                                  <td class="text-right">
                                    <strong>₹ 499</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="3"></td>
                                  <td class="text-right">
                                    <strong>Total</strong>
                                  </td>
                                  <td class="text-right">
                                    <strong>
                                      ₹{" "}
                                      {
                                        orders[indexState].order_detail
                                          .order_amount
                                      }
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 text-right identity">
                            {/* <p>
                          Designer identity
                          <br />
                          <strong>Jeffrey Williams</strong>
                        </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- END INVOICE --> */}
                </div>
              </div>
            ) : null}
          </Modal.Body>
        ) : null}
      </Modal>
      <ToastContainer className="p-3" position={"top-end"}>
        <Toast show={showB}>
          <Toast.Header
            style={{
              backgroundColor: "darkcyan",
              color: "white",
            }}
            onClick={toggleShowB}
            closeButton={true}
          >
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Delete Order</strong>
            <small>
              <img
                src="https://img.freepik.com/premium-vector/hand-drawn-green-icon-red-backdrop-checkmark-right-vector-icon-hand-drawn_194782-807.jpg?w=2000"
                height={"30px"}
              />
            </small>
          </Toast.Header>
          <Toast.Body>
            Your product successfully deleted to my orders.
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default Orders;
