import React from "react";
import SailorsPrivacy from "../images/sailors-privacy.png";

function Refund() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-3"></div>
        <div className="col-2">
          <img src={SailorsPrivacy} height="80px"></img>
        </div>
        <div className="col-6 mt-5">
          <h3 style={{ color: "red" }}>REFUND POLICY</h3>
        </div>
      </div>
      <div className="row mt-5">
        <div>
          <p>Our Refund Policy was last updated 23/02/2023</p>
          <p>Thank you for shopping at www.thesailorschoice.com</p>
          <p>
            If, for any reason, you are not completely satisfied with a purchase
            we invite you to review our policy on refunds.
          </p>
          <p>
            The following terms are applicable for any products that you
            purchased with us.
          </p>
          <p>
            We will fully reimburse you no later than 7 days from the day on
            which we fail to deliver your order before your ship departure.
          </p>
          <p>
            We reserve the right to refuse returns of any merchandise that you
            purchased on our website. Because we clear customs for all your
            orders and deliver to you.
          </p>
          <strong>
            <u>
              <h6>Contact Us</h6>
            </u>
          </strong>
          <p>
            If you have any questions about our Refunds Policy, please contact
            us:
          </p>
          <p>By visiting this page on our website: www.thesailorschoice.com</p>
          <p>By sending us an email: info.in@thesailorschoice.com</p>
        </div>
      </div>
    </div>
  );
}

export default Refund;
