// import "./style.css";
import React, { useCallback } from "react";
import { Container, Table, Row, Button } from "react-bootstrap";
// import { connect } from "react-redux";
import { useEffect, useState } from "react";
import db from "./firebaseConfig";
import ProductTrElement from "./tableUtility";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";
import Payment from "./payment";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
// import ProductTrElement from "../components/productSlider/tableUtility";

class CartListPage extends React.Component {
  constructor(props) {
    super(props);
    // Don't do this!
    this.state = {
      subTotalPrice: 0,
      tax: 499,
      totalPrice: 499,
      totalProduct: [],
      show: false,
      showB: false,
    };
  }
  // let cart = props.priceAmount;

  // const [subTotalPrice, setsubTotalPrice] = useState(0);
  // const [tax, setTax] = useState(499);
  // const [totalPrice, setTotalPrice] = useState(0);
  // const [totalProduct, setTotalProducts] = useState([]);
  handleClose = () => this.setState({ show: false });

  componentDidMount() {
    this.productFetch();
    let price = 447;
    // cart.forEach((item) => {
    //   price += item.qty * item.price;
    // });
    // console.log(cart);
    // let priceAdd =
    //   price + this.props.priceAmount !== undefined ? this.props.priceAmount : 0;

    // if (cart.length === 0) {
    //   setTax(0);
    //   let cartShow = document.querySelector(".cartShow");
    //   let table = document.querySelector("Table");
    //   table.style.display = "none";
    //   cartShow.innerHTML += "No Products in cart list";
    // }
    // this.priceAd();
  }
  // useEffect(() => {

  // }, [cart, totalPrice, setTotalPrice, tax, setTax]);

  priceAd = () => {
    let price = 0;
    this.setState({ subTotalPrice: price });
    this.setState({ totalPrice: price + this.state.tax });
  };
  subTotalPriceAdd = (price) => {
    console.log(price);
    this.setState({ subTotalPrice: price });
    this.setState({ totalPrice: price + this.state.tax });
  };
  productFetch = () => {
    const product = [];

    db.collection("add_cart")
      .where(
        "customer_details.custom_num",
        "==",
        localStorage.getItem("customerNumber")
      )
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState({ totalProduct: [] });
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let data = doc.data();
          data.docId = doc.id;
          // if (data.custom_num !== null && !product.includes(data)) {
          product.push(data);
          // }
        });
        this.setState({ totalProduct: product });
        product.map((val, index) => {
          document.getElementById("quantity" + index).value = val.quantity;
          document.getElementById("price" + index).innerHTML =
            val.quantity * val.product.product_highlights.Price_range;
          this.setState({
            subTotalPrice:
              parseInt(this.state.subTotalPrice) +
              parseInt(val.product.product_highlights.Price_range),
            totalPrice:
              parseInt(this.state.totalPrice) +
              parseInt(val.product.product_highlights.Price_range),
          });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  changeQuantity(quantity, id, price) {
    let quantityUpdate = document.getElementById("quantity" + id).value;
    let priceUpdate = quantityUpdate * price.replace(/₹/g, "");
    // document.getElementById("quan" + id).value = priceUpdate;
    document.getElementById("price" + id).innerHTML = priceUpdate;
    let totalAdd = 0;
    let stateQuantityUpdate = this.state.totalProduct;
    for (let index = 0; index < this.state.totalProduct.length; index++) {
      if (id !== index) {
        const element = document.getElementById("price" + index).innerHTML;
        totalAdd = totalAdd + element;
        console.log(this.state.totalProduct[index].quantity);
      }
      stateQuantityUpdate[index].quantity = document.getElementById(
        "quantity" + index
      ).value;
      console.log(stateQuantityUpdate);
    }
    this.setState({
      subTotalPrice: priceUpdate + parseInt(totalAdd),
      totalPrice: parseInt(priceUpdate) + parseInt(totalAdd) + 499,
      totalProduct: stateQuantityUpdate,
    });
  }

  deleteCart(docId) {
    // Specify the document you want to delete
    const docRef = db.collection("add_cart").doc(docId);

    // Delete the document
    docRef
      .delete()
      .then(() => {
        this.setState({ showB: true });
        this.productFetch();

        console.log("Document successfully deleted.");
      })
      .catch((error) => {
        console.error("Error deleting document:", error);
      });
  }

  toggleShowB = () => this.setState({ showB: false });

  render() {
    const { totalProduct } = this.state;
    return (
      <div className="productSlider mb-5 mt-5">
        <Container>
          <h5 className="text-left mb-4 ps-2">Cart List</h5>
          <Row>
            <div className="col-md-9 cartShow">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {totalProduct.map((val, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <img
                          alt="product"
                          src={val.product.product_main_img}
                          height={"80px"}
                        />
                      </td>
                      <td>{val.product.product_name}</td>
                      <td>
                        <input
                          type="number"
                          id={"quantity" + index}
                          onChange={(e) =>
                            this.changeQuantity(
                              e.target.value,
                              index,
                              val.product.product_highlights.Price_range
                            )
                          }
                        />
                      </td>
                      <td id={"price" + index}></td>
                      <td>
                        <button
                          onClick={() => this.deleteCart(val.docId)}
                          className="btn-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* <Table bordered hover responsive="sm">
                <thead>
                  <tr>
                    <th>Product Img</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Sub Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {this.state.totalProduct.length > 0 ? (
                  <tbody>
                    {this.state.totalProduct.map((product, idx) => (
                      <ProductTrElement
                        product={product}
                        index={idx}
                        isWish={false}
                        isCart={true}
                        subTotalPriceAdd={this.subTotalPriceAdd}
                      />
                    ))}
                  </tbody>
                ) : null}
              </Table> */}
            </div>
            <div className="col-md-3 cartSum boxShadaw bg-light p-4">
              <h5 className="text-left mb-4 pb-2">Cart Price</h5>
              <div className="d-flex justify-content-between mb-3">
                <h6 className="fw-normal">CC and Forward Charge :</h6>
                <span>{this.state.tax} INR</span>
              </div>
              <div className="d-flex justify-content-between mb-4">
                <h6 className="fw-normal">SubTotal Price :</h6>
                <span>{this.state.subTotalPrice} INR</span>
              </div>
              <div className="d-flex justify-content-between fw-bold">
                <h6>Total Price :</h6>
                <span>{this.state.totalPrice} INR</span>
              </div>
              <Button
                onClick={() => this.setState({ show: true })}
                variant="dark"
                size="md"
                className="mt-4 w-100"
              >
                pay now
              </Button>
            </div>
          </Row>
          <Payment
            productDetail={this.state.totalProduct}
            show={this.state.show}
            handleClose={this.handleClose}
            amount={this.state.totalPrice}
          />
          <ToastContainer className="p-3" position={"top-end"}>
            <Toast show={this.state.showB}>
              <Toast.Header
                style={{
                  backgroundColor: "darkcyan",
                  color: "white",
                }}
                onClick={this.toggleShowB}
                closeButton={true}
              >
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Add to cart</strong>
                <small>
                  <img
                    src="https://img.freepik.com/premium-vector/hand-drawn-green-icon-red-backdrop-checkmark-right-vector-icon-hand-drawn_194782-807.jpg?w=2000"
                    height={"30px"}
                  />
                </small>
              </Toast.Header>
              <Toast.Body>
                Your product successfully deleted to add cart.
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </Container>
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     cart: state.rCart.cart,
//   };
// };

export default CartListPage;
