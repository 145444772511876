import React from "react";
// import "./style.css";
import Button from "react-bootstrap/Button";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import db from "./firebaseConfig";

class ProductTrElement extends React.Component {
  // const [inputQty, setinputQty] = useState(props.index + 1);
  // const [subTotal, setsubTotal] = useState(0);
  constructor(props) {
    super(props);
    // Don't do this!
    this.state = {
      inputQty: 0,
      subTotal: 0,
    };
  }
  componentDidMount() {
    console.log(this.props.product.product);
    const input = document.querySelector("input");
    const subTotalll =
      this.state.inputQty *
      this.props.product.product.product_highlights.Price_range;
    let subTotalAdd = this.state.subTotal + subTotalll;
    this.setState({ subTotal: subTotalAdd });
    let subTotShow = input.parentNode.parentNode.children[4];
    subTotShow.innerHTML = `${subTotalll}`;
  }
  // useEffect(() => {

  // }, [inputQty, setsubTotal, props.product.price]);

  onChangeQuantity = async (event) => {
    event.preventDefault();
    let btn = event.currentTarget;
    this.setState({ inputQty: btn.value });
    if (btn.value === btn.max) {
      alert("This is the last quantity for this product");
    }
    let subTotall =
      btn.value * this.props.product.product.product_highlights.Price_range;
    console.log(
      subTotall,
      this.props.product.product.product_highlights.Price_range,
      btn.value
    );
    let subTotalAdd = subTotall + this.state.subTotal;
    this.setState({ subTotal: subTotalAdd });
    let subTotShow = btn.parentNode.parentNode.children[4];
    subTotShow.innerHTML = `${subTotall}`;
    setTimeout(() => {
      this.props.subTotalPriceAdd(subTotall);
    }, 1000);
  };

  deleteCart = (id) => {
    console.log(id);
    db.collection("add_cart")
      .doc(id + localStorage.getItem("customerNumber"))
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        window.location.reload(false);
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };

  render() {
    return (
      <>
        {this.props.product.product.product_highlights.Price_range > 0 ? (
          <tr key={this.props.product.id}>
            <td>
              <Link to={`/product/${this.props.product.id}`}>
                <img
                  src={this.props.product.product.product_main_img}
                  alt="productImg"
                  style={{ height: "40px" }}
                  // onClick={() => LoadCurrentItem(props.product)}
                />
              </Link>
            </td>
            <td
            //   onClick={() => LoadCurrentItem(props.product)}
            >
              <Link to={`/product/${this.props.product.id}`}>
                {this.props.product.product.product_name}
              </Link>
            </td>
            <td className="price-new">
              {this.props.product.product.product_highlights.Price_range} INR
            </td>
            {!this.props.isWish ? (
              <td>
                <input
                  type="number"
                  id="qty"
                  name="qty"
                  min="1"
                  max={this.props.product.maxQuantity}
                  step="1"
                  // defaultValue="1"
                  value={this.state.inputQty}
                  onChange={this.onChangeQuantity}
                />
              </td>
            ) : (
              ""
            )}
            {!this.props.isWish ? (
              <td className="subTotalShow">
                {this.props.product.product.product_highlights.Price_range}
              </td>
            ) : (
              ""
            )}
            <td>
              {!this.props.isCart ? (
                <Button
                  variant="dark"
                  size="sm"
                  // onClick={(e) =>
                  // addToCart(e, props.product, props.product.id)}
                >
                  Add To Cart
                </Button>
              ) : (
                <Button
                  variant="dark"
                  size="sm"
                  className="ms-2"
                  // onClick={(e) => deleteFromCart(e, props.product.id)}
                >
                  <Icon.Trash
                    onClick={() =>
                      this.deleteCart(this.props.product.product.docId)
                    }
                  ></Icon.Trash>
                </Button>
              )}
              {!this.props.isWish ? (
                ""
              ) : (
                <Button
                  variant="dark"
                  size="sm"
                  className="ms-2"
                  // onClick={(e) => deleteFromWish(e, props.product)}
                >
                  <Icon.Trash></Icon.Trash>
                </Button>
              )}
            </td>
          </tr>
        ) : null}
      </>
    );
  }
}

export default ProductTrElement;
