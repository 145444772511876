import React from "react";
import SailorsPrivacy from "../images/sailors-privacy.png";

export default function ShippingPlicy() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-3"></div>
        <div className="col-2">
          <img src={SailorsPrivacy} height="100px"></img>
        </div>
        <div className="col-6 mt-5">
          <h3 style={{ color: "red" }}>SHIPPING & CANCELLATION POLICIES</h3>
        </div>
      </div>
      <p>Last updated: May 13, 2023</p>
      <strong>
        <h6 className="mt-3">Refund policy</h6>
      </strong>
      <p>
        Thank you for shopping at www.thesailorschoice.com, operated by
        SeafarersChoice Private Limited. Due to the nature of our products, we
        are unable to accept any returns as it is not possible to take them out
        of the port. However, if we are unable to deliver your order before your
        ship's departure, we will provide a full refund as per our refund
        policy. If you have any questions or concerns about your order, please
        feel free to contact us
      </p>
      <strong>
        <h6 className="mt-3">Shipping policy</h6>
      </strong>
      <p>
        Thank you for choosing to shop at www.thesailorschoice.com, operated by
        SeafarersChoice Private Limited. We provide shipping services for all
        our products. Once your order is placed, our team will work on clearing
        port authority and CISF inspection, after which we will deliver your
        products to your ship before your ship's departure from the port, after
        completing all necessary clearance procedures. Please note that the
        delivery time may vary based on the location and other factors that are
        beyond our control. We will do our best to ensure that your products are
        delivered to you before your ship's departure from the port. If you have
        any questions or concerns regarding our shipping policy, please feel
        free to contact us
      </p>
      <strong>
        <h6 className="mt-3"> Delivery Timeline</h6>
      </strong>
      <p>
        At sailorschoice.com, we strive to provide you with efficient and timely
        delivery services. Please take note of the following information
        regarding our minimum and maximum delivery timelines:
      </p>
      <h6 className="mt-4">Minimum Delivery Timeline:</h6>
      Our minimum delivery timeline is set at 1 business day. This means that
      once your order has been processed and confirmed, you can expect to
      receive your package within 1 business day.
      <h6 className="mt-4">Maximum Delivery Timeline:</h6>
      <p>
        To ensure a smooth and reliable delivery experience, our maximum
        delivery timeline is also set at 1 business day. This means that under
        normal circumstances, your package should be delivered to your
        designated address within 1 business day from the date of shipment.
      </p>
    </div>
  );
}
