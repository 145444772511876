import React from "react";
import { Button } from "react-bootstrap";

function Contact() {
  return (
    <div>
      {/* <CarouselPart /> */}
      <div className="container" style={{ marginTop: "5%" }}>
        <div className="row">
          <div className="col-md-6">
            <h2>
              <strong>Contact Us</strong>
            </h2>
            <div>
              <h4 className="mt-3">SEAFARERSCHOICE PRIVATE LIMITED</h4>
            </div>
            <div>
              <p className="mt-3">
                116 Alwarkurichi Ambasamudram main Road, Alwarkurichi.
              </p>
              <p className="mt-3">Tenkasi District - 627 412.</p>
              <p className="mt-3">Tamilnadu</p>
              <p className="mt-3">India</p>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img
              src="
            https://firebasestorage.googleapis.com/v0/b/surfgeo-sale.appspot.com/o/contact-us%2F485563.webp?alt=media&token=b0893160-f6df-4172-b081-ea9e88906fec"
              alt="contact-img"
              height="400px"
              width={"400px"}
            /> */}
            <div>
              <iframe
                width="600"
                height="450"
                frameborder="0"
                style={{ border: "0" }}
                src="https://www.openstreetmap.org/export/embed.html?bbox=80.1560,12.8376,80.3018,13.0039&layer=mapnik&marker=13.0337,80.2350"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
