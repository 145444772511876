import React, { useEffect, useState } from "react";
import db from "./firebaseConfig";

function Account() {
  const [userName, setUserName] = useState("");
  const [userNum, setUserNum] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [customerId, setCustomerId] = useState("");

  useEffect(() => {
    fetchAccountDetail();
  }, []);

  function fetchAccountDetail() {
    db.collection("Users")
      .where("userMob", "==", localStorage.getItem("customerNumber"))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          setUserName(doc.data().userName);
          setUserEmail(doc.data().userEmail);
          setUserNum(doc.data().userMob);
          setCustomerId(doc.data().customerId);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

  return (
    <div className="container">
      <div className="row" style={{ marginTop: "10%" }}>
        <h5>My Account</h5>
        <div className="col-sm-6">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/thesailorschoiceonline.appspot.com/o/download%20(8).png?alt=media&token=99b2c187-15f6-42aa-845a-2daf595e25ee"
            alt=""
          />
        </div>
        <div className="col-sm-6">
          <div className="col-sm-6">
            <div class="form-outline mb-4">
              <label class="form-label" for="form2Example1">
                User Name
              </label>
              <input
                type="email"
                id="form2Example1"
                class="form-control"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div class="form-outline mb-4">
              <label class="form-label" for="form2Example1">
                Email
              </label>
              <input
                type="email"
                id="form2Example1"
                class="form-control"
                value={userEmail}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div class="form-outline mb-4">
              <label class="form-label" for="form2Example1">
                Mobile number
              </label>
              <input
                type="email"
                id="form2Example1"
                class="form-control"
                value={userNum}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div class="form-outline mb-4">
              <label class="form-label" for="form2Example1">
                Customer id
              </label>
              <input
                type="email"
                id="form2Example1"
                class="form-control"
                value={customerId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
