import React from "react";
import { useNavigate, Outlet, Link } from "react-router-dom";
import jQuery from "jquery";
// import db from './firebaseConfig';
import { useState, useEffect } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import logo from "../images/logo.png";
import "./css/navbar.scss";
import "./css/update-style.css";
import { auth } from "./firebaseConfig";

(function ($) {
  // Begin jQuery
  $(function () {
    // DOM ready
    // If a link has a dropdown, add sub menu toggle.
    $("nav ul li a:not(:only-child)").click(function (e) {
      $(this).siblings(".nav-dropdown").toggle();
      // Close one dropdown when selecting another
      $(".nav-dropdown").not($(this).siblings()).hide();
      e.stopPropagation();
    });
    // Clicking away from dropdown will remove the dropdown className
    $("html").click(function () {
      $(".nav-dropdown").hide();
    });
    // Toggle open and close nav styles on click
    $("#nav-toggle").click(function () {
      $("nav ul").slideToggle();
    });
    // Hamburger to X toggle
    $("#nav-toggle").on("click", function () {
      this.classList.toggle("active");
    });
  }); // end DOM ready
})(jQuery); // end jQuery

// Close the dropdown if the user clicks outside of it
window.onclick = function (event) {
  if (!event.target.matches(".dropbtn")) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
  }
};
let loginValue = "";
const Layout = () => {
  const [cityinfo, setCityInfo] = useState([]);
  // const [loginValue , setLoginValue] = useState('');

  useEffect(() => {
    // Fetchcitydata()
    loginValue = localStorage.getItem("login");
    console.log(loginValue);
  }, []);
  // Close the dropdown if the user clicks outside of it
  //   const Fetchcitydata = ()=>{

  // 		db.collection("Project_Details").get().then((querySnapshot) => {

  // 			// Loop get the data fetching here
  // 			querySnapshot.forEach(element => {
  // 				var data = element.data();
  //         data.docId = element.id;
  // 				setCityInfo(arr => [...arr , data]);
  // 			});
  //   })
  // }
  return <Navbar cities={cityinfo} />;
};

const Navbar = ({ cities }) => {
  const navigate_appartment = useNavigate();

  function goToStatus(nav_city) {
    let data1 = nav_city.trim().split(" ");
    var trim_data = data1.join("-");
    var trim_data_lower_case = trim_data.toLowerCase();
    navigate_appartment(`/status/${trim_data_lower_case}`, {
      state: { id: trim_data, seo_section: nav_city },
    });
    window.location.reload();
  }

  const groupBy_ = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const groupBycity_ = groupBy_("city");
  var split_group_by_city = groupBycity_(cities);

  const groupByStatus_ = groupBy_("project_status");
  var split_group_by_status = groupByStatus_(cities);

  function apartment_click(nav_city, category_name) {
    var trim_data_lower_case = nav_city.toLowerCase();
    navigate_appartment(
      `/${category_name + "-" + trim_data_lower_case}`,

      { state: { id: nav_city } }
    );
    window.location.reload();
  }

  // function apartment_click_villa(nav_city,category_name){
  //   var trim_data_lower_case = nav_city.toLowerCase()
  //   navigate_appartment(

  //     `/${'housetype/villa-'+trim_data_lower_case}`
  // )
  //   window.location.reload();
  // }

  function Category_Click(pass_data) {
    let category_ = pass_data;
    let data1 = pass_data.trim().split(" ");
    var trim_data = data1.join("-");
    var trim_data_lower_case = trim_data.toLowerCase();
    navigate_appartment(`/property-category/${trim_data_lower_case}`, {
      state: { category: pass_data },
    });
    window.location.reload();
  }

  function static_page_click() {
    navigate_appartment(`/contact`);
    window.location.reload();
  }
  function homePage() {
    navigate_appartment("/");
    window.location.reload();
  }

  const logout = () => {
    console.log("function call");
    auth
      .signOut()
      .then(() => {
        // Sign-out successful.
        localStorage.setItem("login", "false");
        navigate_appartment("/");
        window.location.reload(false);
        console.log("logout");
      })
      .catch((error) => {
        // An error happened.
      });
  };
  return (
    <div>
      <section className="navigation" id="default-nav">
        <div className="nav-container">
          <div className="brand">
            <a onClick={() => homePage()}>
              <img src={logo} alt="sailors-choice-logo" />
            </a>
          </div>
          <nav>
            <div className="nav-mobile">
              <a id="nav-toggle" href="#!">
                <span></span>
              </a>
            </div>
            <ul className="nav-list">
              <li>
                <a onClick={() => homePage()}>Home</a>
              </li>
              {/* {loginValue === "true" ? ( */}
              <Link to="/products">
                <li>
                  <a>Products</a>
                  {/* <ul className="nav-dropdown">
                <Link to='/products'>
                <li>
                  Electronics
                </li>
                </Link>
                <li>
                  Mobile phones
                </li>
                </ul> */}
                </li>
              </Link>
              {/* ) : null} */}
              {loginValue === "false" ? (
                <Link to="/about-us">
                  <li>
                    <a>About us</a>
                  </li>
                </Link>
              ) : null}
              <Link to="/card-list">
                <li>
                  <a>Cart</a>
                  {/* <ul className="nav-dropdown">
                <Link to='/products'>
                <li>
                  Electronics
                </li>
                </Link>
                <li>
                  Mobile phones
                </li>
                </ul> */}
                </li>
              </Link>
              {loginValue === "false" ? null : (
                <li>
                  <a href="/my-account">My Account</a>
                </li>
              )}
              {loginValue === "false" ? (
                <li>
                  <a href="/privacy-policy">Privacy policy</a>
                </li>
              ) : (
                <Link to="/orders">
                  <li>
                    <a>My orders</a>
                  </li>
                </Link>
              )}
              {loginValue === "false" ? (
                <Link to="/login">
                  <li>
                    <a>SignIn</a>
                  </li>
                </Link>
              ) : (
                <li onClick={() => logout()}>
                  <a>SignOut</a>
                </li>
              )}
              <Link to="/contact-us">
                <li>
                  <a>Contact us</a>
                </li>
              </Link>
            </ul>
          </nav>
        </div>
      </section>

      <Outlet />
    </div>
  );
};

export default Layout;
