import { React, useEffect, useState } from "react";
import "./css/login.css";
import { useNavigate, Outlet, Link } from "react-router-dom";
import db from "./firebaseConfig";
import firebase from "firebase";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function SignUp() {
  const navigation = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [pass, setPass] = useState("");
  const [lastNum, setLastNum] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    navigation("/sign-in");
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchOrderId();
  }, []);

  function fetchOrderId() {
    // let data= db.collection('Users').orderBy('userNum','desc').limit(1).get()
    // console.log(data,'data')
    db.collection("Users")
      .where("userNum", ">", 0)
      .orderBy("userNum", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let data = doc.data();
          setLastNum(data.userNum);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }
  function create() {
    let data = {
      userName: name,
      userEmail: email,
      userMob: mobNo,
      userNum: lastNum + 1,
      customerId: "scc-" + parseInt(lastNum + 1),
    };
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, pass)
      .then(async () => {
        // Signed in
        await db.collection("Users").doc().set(data);
        handleShow();
      });
  }
  return (
    <div>
      <div>
        <div class="container mt-2">
          <div class="row justify-content-md-center">
            <div class="col-md-auto singIn-box">
              <h4 className="text-center">Sign Up</h4>
              <div>
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    onChange={(e) => setName(e.target.value)}
                    aria-describedby="emailHelp"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Mobile number
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    onChange={(e) => setMobNo(e.target.value)}
                    aria-describedby="emailHelp"
                  />
                  <div id="emailHelp" class="form-text">
                    We'll never share your mobile number with anyone else.
                  </div>
                </div>
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    onChange={(e) => setEmail(e.target.value)}
                    aria-describedby="emailHelp"
                  />
                  <div id="emailHelp" class="form-text">
                    We'll never share your email with anyone else.
                  </div>
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    onChange={(e) => setPass(e.target.value)}
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3 form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                  />
                  <label class="form-check-label" for="exampleCheck1">
                    Please agree the{" "}
                    <a href="/privacy-policy">terms & conditions</a>
                  </label>
                  <Link to="/sign-in">SignIn</Link>
                </div>
                <button onClick={() => create()} class="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "green" }}>Welcome !!!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            you're successfully joined on seaFearschoice and login to make a
            order!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default SignUp;
