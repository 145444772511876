import React, { useCallback, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import useRazorpay from "react-razorpay";
import axios from "axios";
import db from "./firebaseConfig";
import { useNavigate } from "react-router-dom";
// import Razorpay from "reac";

function Payment(props) {
  const navigation = useNavigate();
  const Razorpay = useRazorpay();

  const [customerPort, setCustomerPort] = useState("");
  const [shipNum, setShipNum] = useState("");
  const [ordName, setOrdName] = useState("");

  // const razorpay = new Razorpay({
  //   key_id: "rzp_test_3kRhKQy4cbfDgH",
  //   key_secret: "dwsR1tt63BZjPHVhytnsT87M",
  // });

  // const instance = new Razorpay({
  //   key_id: "rzp_test_3kRhKQy4cbfDgH",
  //   key_secret: "dwsR1tt63BZjPHVhytnsT87M",
  // });

  // razorpay.orders.create(
  //   {
  //     amount: 50000, // amount in paisa
  //     currency: "INR",
  //     receipt: "order_rcptid_11",
  //     payment_capture: 1,
  //   },
  //   (error, order) => {
  //     console.log(order);
  //   }
  // );

  const options = {
    key: "rzp_test_3kRhKQy4cbfDgH",
    amount: props.amount * 100,
    name: "thesailorschoice payment portal",
    description:
      "Available to customers when purchasing products from an our website. It should provide customers with accepted by the website".substring(
        0,
        255
      ),
    image:
      "https://firebasestorage.googleapis.com/v0/b/thesailorschoiceonline.appspot.com/o/logo%2Ffavicon.ico?alt=media&token=cb6b0341-a380-4c42-9b41-d4a8b0db7b2a",
    handler: function (response) {
      //   setOrderId(response.razorpay_order_id);
      //   setPaymentId(response.razorpay_payment_id);
      //   setSignature(response.razorpay_signature);
      console.log(response);
      onlinePay(response.razorpay_payment_id);
    },
    prefill: {
      name: ordName,
      email: localStorage.getItem("customerEmail"),
      contact: localStorage.getItem("customerNumber"),
    },
    theme: {
      color: "#F37254",
    },
  };

  async function cashOnDelivery() {
    // Add a new document in collection "cities"
    storeOrderData();
  }

  function onlinePay(razorpayId) {
    // Specify the collection you want to count the documents in
    const collectionRef = db.collection("order_details");

    // Get the documents in the collection
    collectionRef
      .get()
      .then((snapshot) => {
        // Count the number of documents
        const documentCount = snapshot.size;

        // db.collection("order_details")
        const collectionRef = db.collection("order_details");
        const docRef = collectionRef.doc();
        docRef
          .set({
            customer_details: {
              cus_email: localStorage.getItem("customerEmail"),
              cus_id: localStorage.getItem("customerId"),
              cus_mob: localStorage.getItem("customerNumber"),
              cus_ship_number: shipNum,
              cus_port: customerPort,
              receiver_name: ordName,
            },
            product_details: props.productDetail,
            order_detail: {
              orderId: "tsc-ord-ch-" + documentCount,
              invoiceId: "tsc-inv-ch-" + documentCount,
              method: "online",
              id: razorpayId,
              order_date:
                new Date().getDate() +
                "-" +
                new Date().getMonth() +
                1 +
                "-" +
                new Date().getFullYear(),
              cc_amount: 499,
              order_amount: props.amount,
            },
            country: "India",
          })
          .then(() => {
            console.log("Document successfully written!");
            navigation("/order-status", { state: docRef.id });
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
        console.log(`Number of documents in the collection: ${documentCount}`);
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
      });
  }

  function storeOrderData() {
    // Specify the collection you want to count the documents in
    const collectionRef = db.collection("order_details");

    // Get the documents in the collection
    collectionRef
      .get()
      .then((snapshot) => {
        // Count the number of documents
        const documentCount = snapshot.size;

        // db.collection("order_details").doc();
        const collectionRef = db.collection("order_details");
        const docRef = collectionRef.doc();
        docRef
          .set({
            customer_details: {
              cus_email: localStorage.getItem("customerEmail"),
              cus_id: localStorage.getItem("customerId"),
              cus_mob: localStorage.getItem("customerNumber"),
              cus_ship_number: shipNum,
              cus_port: customerPort,
              receiver_name: ordName,
            },
            product_details: props.productDetail,
            order_detail: {
              orderId: "tsc-ord-ch-" + documentCount,
              invoiceId: "tsc-inv-ch-" + documentCount,
              method: "cod",
              order_date:
                new Date().getDate() +
                "-" +
                new Date().getMonth() +
                "-" +
                new Date().getFullYear(),
              cc_amount: 499,
              order_amount: props.amount,
            },
            country: "India",
          })
          .then(() => {
            console.log("Document successfully written!", docRef.id);
            navigation("/order-status", { state: docRef.id });
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
        console.log(`Number of documents in the collection: ${documentCount}`);
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
      });
  }

  const [orderId, setOrderId] = useState("");

  async function openCheckout() {
    const paymentObject = new Razorpay(options);
    paymentObject.open();
    // await createOrder();
    // displayRazorpay();

    // const options = {
    //   amount: 100,
    //   currency: "INR",
    //   name: "Acme Corp",
    //   description: "Test payment",
    //   image: "https://example.com/your_logo.png",
    //   handler: function (response) {
    //     console.log(response.razorpay_payment_id);
    //     alert("Payment successfull");
    //   },
    //   prefill: {
    //     name: "John Doe",
    //     email: "john@example.com",
    //     contact: "9999999999",
    //   },
    // };

    // const paymentObject = razorpay.orders.create(options);

    // paymentObject.then((data) => {
    //   const options = {
    //     key: "rzp_test_3kRhKQy4cbfDgH",
    //     amount: data.amount,
    //     currency: data.currency,
    //     name: "Acme Corp",
    //     description: "Test payment",
    //     order_id: data.id,
    //     handler: function (response) {
    //       console.log(response.razorpay_payment_id);
    //       alert("Payment successfull");
    //     },
    //     prefill: {
    //       name: "John Doe",
    //       email: "john@example.com",
    //       contact: "9999999999",
    //     },
    //   };

    //   const rzp1 = new window.Razorpay(options);
    //   rzp1.open();
    // });

    // const options = {
    //   key: "rzp_test_3kRhKQy4cbfDgH",
    //   amount: 50000, // amount in paisa
    //   currency: "INR",
    //   name: "MyStore",
    //   description: "Test transaction",
    //   image: "https://your-logo-url.png",
    //   order_id: "ORDER_ID_FROM_RAZORPAY",
    //   handler: function (response) {
    //     console.log(response);
    //   },
    //   prefill: {
    //     name: "John Doe",
    //     email: "johndoe@example.com",
    //     contact: "9999999999",
    //   },
    //   notes: {
    //     address: "Razorpay Corporate Office",
    //   },
    //   theme: {
    //     color: "#F37254",
    //   },
    // };

    // const paymentForm = new razorpay.paymentForm(options);
    // paymentForm.open();

    // const rzp = new window.Razorpay(options);
    // rzp.on("payment.failed", function (response) {
    //   alert(response.error.description);
    // });
    // rzp.open();
  }

  //   const Razorpay = useRazorpay();
  //   function loadScript(src) {
  //     return new Promise((resolve) => {
  //       const script = document.createElement("script");
  //       script.src = src;
  //       script.onload = () => {
  //         resolve(true);
  //       };
  //       script.onerror = () => {
  //         resolve(false);
  //       };
  //       document.body.appendChild(script);
  //     });
  //   }

  //   async function displayRazorpay() {
  //     const res = await loadScript(
  //       "https://checkout.razorpay.com/v1/checkout.js"
  //     );

  //     if (!res) {
  //       alert("Razorpay SDK failed to load. Are you online?");
  //       return;
  //     }

  //     const result = await axios.post("http://localhost:3000/card-list");

  //     if (!result) {
  //       alert("Server error. Are you online?");
  //       return;
  //     }

  //     const { amount, id: order_id, currency } = result.data;

  //     const options = {
  //       key: "rzp_test_3kRhKQy4cbfDgH", // Enter the Key ID generated from the Dashboard
  //       amount: "33333",
  //       currency: "INR",
  //       name: "Soumya Corp.",
  //       description: "Test Transaction",
  //       image: "",
  //       order_id: "1",
  //       handler: async function (response) {
  //         const data = {
  //           orderCreationId: "1",
  //           razorpayPaymentId: response.razorpay_payment_id,
  //           razorpayOrderId: response.razorpay_order_id,
  //           razorpaySignature: response.razorpay_signature,
  //         };

  //         // const result = await axios.post(
  //         //   "http://localhost:5000/payment/success",
  //         //   data
  //         // );

  //         // alert(result.data.msg);
  //       },
  //       prefill: {
  //         name: "Soumya Dey",
  //         email: "SoumyaDey@example.com",
  //         contact: "9999999999",
  //       },
  //       notes: {
  //         address: "Soumya Dey Corporate Office",
  //       },
  //       theme: {
  //         color: "#61dafb",
  //       },
  //     };

  //     const paymentObject = new window.Razorpay(options);
  //     paymentObject.open();
  //   }

  return (
    <div>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <label>Ship port</label>
                <Form.Select
                  onChange={(e) => setCustomerPort(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value={""}>Open this select port</option>
                  <option value="chennai-ennore">Chennai-Ennore</option>
                </Form.Select>
              </div>
              <div className="col-12">
                <label>Enter a Ship number</label>
                <input
                  type="text"
                  className="form-control"
                  id="brochure_mail_data"
                  onChange={(e) => setShipNum(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label>Enter a receiver name</label>
                <input
                  type="text"
                  className="form-control"
                  id="brochure_mail_data"
                  onChange={(e) => setOrdName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <p className="mt-3"> Make a payment in two ways</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => cashOnDelivery()}>
            Cash on delivery
          </Button>
          <Button variant="secondary" onClick={() => openCheckout()}>
            Online
          </Button>
          <form>
            <script
              src="https://checkout.razorpay.com/v1/payment-button.js"
              data-payment_button_id="pl_LQYeUQibzsWunc"
              async
            ></script>{" "}
          </form>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Payment;
