import "./css/paymentSuccess.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import db from "./firebaseConfig";
import html2pdf from "html2pdf.js";
import logo from "../images/logo.png";

function PaymentSuccess() {
  const location = useLocation();
  const [lgShow, setLgShow] = useState(false);
  const [ordDetail, setOrdDetail] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    db.collection("order_details")
      .doc(location.state)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // Convert to City object
          var city = doc.data();
          // Use a City instance method
          console.log(city);
          setOrdDetail(doc.data());
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  function invoiceDownload() {
    const element = document.getElementById("pdf-content"); // Provide the ID of the HTML element to convert

    html2pdf().from(element).save(`${ordDetail.order_detail.invoiceId}.pdf`);
  }
  return (
    <div className="container">
      <div className="row justify-content-md-center">
        <div class="card">
          <div
            style={{
              borderRadius: "200px",
              height: "200px",
              width: "200px",
              background: "#F8FAF5",
              margin: "0 auto",
            }}
            className="text-center"
          >
            <i class="checkmark ">✓</i>
          </div>
          <h1 className="text-center">Success</h1>
          <p className="text-center">
            We received your purchase request;
            <br /> we'll be in touch shortly!
          </p>
        </div>
        <button
          onClick={() => setLgShow(true)}
          className="col-md-2 text-center mt-5 btn-danger"
        >
          Show Invoice
        </button>
      </div>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button onClick={() => invoiceDownload()} className="btn-danger">
            Download
          </button>
          {Object.keys(ordDetail).length > 0 ? (
            <div id="pdf-content" class="container">
              <div class="row">
                {/* <!-- BEGIN INVOICE --> */}
                <div class="col-xs-12">
                  <div class="grid invoice">
                    <div class="grid-body">
                      <div class="invoice-title">
                        <div class="row">
                          <div class="col-md-12"></div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col-md-8">
                            <h2>
                              invoice
                              <br />
                              <span class="small">
                                order #{ordDetail.order_detail.orderId}
                              </span>
                            </h2>
                          </div>
                          <div class="col-md-4">
                            <img src={logo} alt="invoice-logo" height="40px" />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-md-6">
                          <address>
                            <strong>Billed To:</strong>
                            <br />
                            SEAFARERSCHOICE.
                            <br />
                            116, Ambasamudram main road
                            <br />
                            Alwarkurichi, Tenkasi District - 627 412.
                            <br />
                            <abbr title="Phone"></abbr>(+91) 8807223822
                          </address>
                        </div>
                        <div class="col-md-6 text-right">
                          <address>
                            <strong>Shipped To:</strong>
                            <br />
                            {ordDetail.customer_details.receiver_name}
                            <br />
                            {ordDetail.customer_details.cus_ship_number}
                            <br />
                            {ordDetail.customer_details.cus_port}
                            <br />
                            {/* <br />
                          {ordDetail.customer_details.cus_id}
                          <br /> */}
                            <abbr title="Phone"></abbr>
                            {ordDetail.customer_details.cus_mob}
                          </address>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <address>
                            <strong>Payment Method:</strong>
                            <br />
                            {ordDetail.order_detail.method}
                            <>
                              {ordDetail.order_detail.method !== "cod" ? (
                                <>
                                  <br />
                                  {ordDetail.order_detail.orderId}
                                  <br />
                                </>
                              ) : null}
                            </>
                          </address>
                        </div>
                        <div class="col-md-6 text-right">
                          <address>
                            <strong>Order Date:</strong>
                            <br />
                            {ordDetail.order_detail.order_date}
                          </address>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <h3>ORDER SUMMARY</h3>
                          <table class="table table-striped">
                            <thead>
                              <tr class="line">
                                <td>
                                  <strong>#</strong>
                                </td>
                                <td class="text-center">
                                  <strong>PRODUCTS</strong>
                                </td>
                                <td class="text-center">
                                  <strong>QUANTITY</strong>
                                </td>
                                <td class="text-right">
                                  <strong>PRICE</strong>
                                </td>
                                <td class="text-right">
                                  <strong>SUBTOTAL</strong>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {ordDetail.product_details.map((val, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <strong>{val.product.product_name}</strong>
                                  </td>
                                  <td class="text-center">{val.quantity}</td>
                                  <td class="text-center">
                                    ₹{" "}
                                    {val.product.product_highlights.Price_range}
                                  </td>
                                  <td class="text-right">
                                    ₹{" "}
                                    {val.quantity *
                                      val.product.product_highlights
                                        .Price_range}
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td colspan="3"></td>
                                <td class="text-right">
                                  <strong>Taxes</strong>
                                </td>
                                <td class="text-right">
                                  <strong>₹ 499</strong>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3"></td>
                                <td class="text-right">
                                  <strong>Total</strong>
                                </td>
                                <td class="text-right">
                                  <strong>
                                    ₹ {ordDetail.order_detail.order_amount}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 text-right identity">
                          {/* <p>
                          Designer identity
                          <br />
                          <strong>Jeffrey Williams</strong>
                        </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- END INVOICE --> */}
              </div>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PaymentSuccess;
