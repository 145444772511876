import React from "react";
import SailorsPrivacy from "../images/sailors-privacy.png";

function AboutUs() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-3"></div>
        <div className="col-2">
          <img alt="sailors-logo" src={SailorsPrivacy} height="100px"></img>
        </div>
        <div className="col-6 mt-5">
          <h3 style={{ color: "red" }}>ABOUT US</h3>
        </div>
      </div>
      <li className="mt-4">
        Seafarerschoice is a private limited entity, registered under Ministry
        of Corporate Affairs, India.
      </li>
      <li className="mt-2">
        Started by group of sailors, in order to fulfill shippers demand, that’s
        why our company slogan is From Us, For Us
      </li>
      <li className="mt-2">
        The ultimate aim of our company is to provide essential need to shippers
        by our e-commerce portal www.thsailorschice.com
      </li>
      <h6 className="mt-5">
        <strong>
          <u>Mission & Vision</u>
        </strong>
      </h6>
      <li className="mt-2">
        Our mission is to organize the port cities various vendors and their
        products under one platform for each and every shippers to easily order
        their needs.
      </li>
      <li className="mt-2">
        Our vision is to be maritime world's most customer centric company; to
        build a place where, shippers can come to find and discover anything
        they might want to buy online through www.thesailorschoice.com
      </li>
    </div>
  );
}

export default AboutUs;
