/* eslint-disable react-hooks/rules-of-hooks */
// This js file is based on Apartment type homes are shown.
// import section start
import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import db from "./firebaseConfig";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useState, useEffect, useMemo } from "react";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { CardMedia } from "@mui/material";
// import Footer from "./Footer";
import { ReactSVG } from "react-svg";
// import locationmarker from "../images/locationmarker.svg";
import "./css/home-styles.css";
import $ from "jquery";
import norecord from "../images/no record found.svg";
// import loadIcon from "./images/loading_icon.gif";
import Pagination from "./Pagination";
import firebase from "firebase";
import Footer from "./Footer";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
// import CityPageFreq from "./freqQuestions/cityPage";
// import section end
// var storage = firebase.storage();
// Main function start
function CategoryWithCityPage() {
  const [info, setInfo] = useState([]);
  const [buildername, setBuilderName] = useState([]);
  const [cityName, setCityName] = useState([]);
  const [builderArea, setBuilderArea] = useState([]);
  const [searchOption, setSearchOption] = useState("");
  const [noData, setNoData] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [area, setArea] = useState("");
  const [name, setName] = useState("");

  var location;
  var pathname_split;
  var cityname_get;
  var category_get;
  var pathname_caps;
  var pathname_caps_category;

  // program to convert first letter of a string to uppercase
  function capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }

  // try {
  // location = useLocation();
  // // var city_get = location.state.id;
  // var pathname_get = location.pathname;
  // pathname_split = pathname_get.split("/")[1];
  // category_get = pathname_split.split("-")[0];
  // cityname_get = pathname_split.split("-")[1];
  // pathname_caps = capitalizeFirstLetter(cityname_get);
  // pathname_caps_category = capitalizeFirstLetter(category_get);
  // console.log(cityname_get);
  // console.log(category_get);

  var title_tab_ = document.title;

  useEffect(() => {
    // setLoadData(true);
    // setNoData(false);
    Fetchdata();
    // the selector will match all input controls of type :checkbox
    // the selector will match all input controls of type :checkbox
    // and attach a click event handler
    $("above-10000").on("click", function () {
      // in the handler, 'this' refers to the box clicked on
      var $box = $(this);
      if ($box.is(":checked")) {
        // the name of the box is retrieved using the .attr() method
        // as it is assumed and expected to be immutable
        var group = "input:checkbox[name='" + $box.attr("name") + "']";
        // the checked state of the group/box on the other hand will change
        // and the current value is retrieved using .prop() method
        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    });

    filterChoose();
  }, []);

  const handleChangeCategory = (event) => {
    setName(event.target.value);
    if (event.target.value !== "") {
      setNoData(true);

      setInfo([]);
      db.collection("Product_Details")
        .where("property_category", "==", event.target.value)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let data = doc.data();
            data.docId = doc.id;
            setInfo((arr) => [...arr, data]);
          });
        })
        .catch((error) => {});
    }
  };
  const handleChangeArea = (event) => {
    setArea(event.target.value);
    if (area !== "") {
      setInfo([]);
      db.collection("Product_Details")
        .where("city", "==", area)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let data = doc.data();
            data.docId = doc.id;
            setInfo((arr) => [...arr, data]);
          });
        })
        .catch((error) => {});
    }
  };

  function filterChoose() {
    $(document).ready(function () {
      $("#above-10000").click(function () {
        if ($(this).prop("checked") === true) {
          setInfo([]);
          console.log("Checkbox 10000 is checked.");
          db.collection("Product_Details")
            .where("product_highlights.Price_range", "<=", "1000")
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                let data = doc.data();
                data.docId = doc.id;
                setInfo((arr) => [...arr, data]);
              });
            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });
        } else if ($(this).prop("checked") === false) {
          console.log("Checkbox is unchecked.");
        }
      });
    });
    $(document).ready(function () {
      $("#above-1000").click(function () {
        if ($(this).prop("checked") === true) {
          console.log("Checkbox 1000 is checked.");
        } else if ($(this).prop("checked") === false) {
          console.log("Checkbox is unchecked.");
        }
      });
    });
    $(document).ready(function () {
      $("#below-1000").click(function () {
        if ($(this).prop("checked") === true) {
          console.log("Checkbox is checked.");
        } else if ($(this).prop("checked") === false) {
          console.log("Checkbox is unchecked.");
        }
      });
    });
    $(document).ready(function () {
      $("#free").click(function () {
        if ($(this).prop("checked") === true) {
          console.log("Checkbox is checked.");
          setInfo([]);
          db.collection("Product_Details")
            .where("product_delivery_status", "==", "Free Delivery")
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                let data = doc.data();
                data.docId = doc.id;
                setInfo((arr) => [...arr, data]);
              });
            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });
        } else if ($(this).prop("checked") === false) {
          console.log("Checkbox is unchecked.");
          Fetchdata();
        }
      });
    });
    $(document).ready(function () {
      $("#paid").click(function () {
        if ($(this).prop("checked") === true) {
          console.log("Checkbox is checked.", $("#paid").attr("value"));
          setArea($("#paid").attr("value"));
          console.log(area);
          setInfo([]);
          db.collection("Product_Details")
            .where("product_delivery_status", "==", "Paid")
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                let data = doc.data();
                data.docId = doc.id;
                setInfo((arr) => [...arr, data]);
              });
            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });
        } else if ($(this).prop("checked") === false) {
          console.log("Checkbox is unchecked.");
          Fetchdata();
        }
      });
    });
  }
  // Data fetching function start

  const Fetchdata = () => {
    let vilaSearchresults = [];

    db.collection("Product_Details")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          var data = doc.data();
          data.docId = doc.id;
          vilaSearchresults.push(data);
        });
        setInfo(vilaSearchresults);
      });
    setNoData(true);
  };

  // Data fetching function end

  // Filter section start
  const [city, setCity] = React.useState("");

  // filter section end

  // Groupby based on city,builder area and builder name
  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  groupBy(buildername);
  var uniquebuildername = [...new Set(buildername)];
  groupBy(cityName);
  var uniqueCityName = [...new Set(cityName)];
  groupBy(builderArea);
  var uniquebuilderArea = [...new Set(builderArea)];

  // Display the result on the page
  // Pagination section
  let PageSize = 30;
  const [currentPage, setCurrentPage] = useState(1);
  const [sliceArray, setSliceArray] = useState([]);

  const splitDataForPagination = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setSliceArray(info.slice(firstPageIndex, lastPageIndex));
  };
  useMemo(() => {
    splitDataForPagination();
  }, [currentPage, info]);

  // pagination section end

  // filter function end
  return (
    <div>
      <div
        // className="background-img"
        style={{ height: "170px", width: "100%", backgroundColor: "gray" }}
      >
        <div
          className="d-flex flex-column w-100 align-items-center justify-content-center"
          style={{ height: "170px", width: "100%" }}
        >
          <h1 className="text-white text-center">Sailors Online Products</h1>
        </div>
      </div>

      <div className="container-fluid pt-5 pb-70">
        <div className="container">
          <div className="row filter-ui">
            {/* <div className="col-md-3">
              <p className="mb-2">Select Location</p>
              <select
                className="opt-true"
                value={pathname_caps}
                onChange={handleChangeCity}
              >
                <option value="">Select Location</option>
                {uniqueCityName.map((assign_city) => (
                  <option>{assign_city}</option>
                ))}
              </select>
            </div> */}
            {/* <div className="col-md-4"> */}
            {/* <p className="mb-2">Select builder</p>
              <select onChange={handleChangeName}>
                <option value="">Select name</option>

                {uniquebuildername.map((builder_name) => (
                  <option>{builder_name}</option>
                ))}
              </select> */}
            {/* <div>
                <h3>Delivery Type</h3>
                <label>
                  <input
                    type="checkbox"
                    class="radio"
                    value="free"
                    name="fooby[2][]"
                    id="free"
                  />
                  Free
                </label>
                <label>
                  <input
                    type="checkbox"
                    class="radio"
                    value="paid"
                    name="fooby[2][]"
                    id="paid"
                  />
                  Paid
                </label>
              </div> */}
            {/* <div class="form-check form-check-inline">
  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
  <label class="form-check-label" for="inlineCheckbox1">Free delivery</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
  <label class="form-check-label" for="inlineCheckbox2">Above 10000</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
  <label class="form-check-label" for="inlineCheckbox2">Above 1000</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
  <label class="form-check-label" for="inlineCheckbox2">Below 1000</label>
</div> */}
            {/* </div> */}
            <div className="col-md-6">
              <p className="mb-2">Select Port</p>
              <select onChange={handleChangeArea}>
                <option value="">Select Port</option>
                <option value="Chennai">Chennai</option>
                <option value="Chennai-Ennore">Chennai-Ennore</option>
                {/* <option value="Tutucorin">Tutucorin</option> */}
              </select>
            </div>
            <div className="col-md-6">
              <p className="mb-2">Category</p>
              <select onChange={handleChangeCategory}>
                <option value="">Select Category</option>
                <option value="Fashion">Fashion</option>
                {/* <option value="Electronics">Electronics</option> */}
                <option value="Food & Drinks">Food & Drinks</option>
                {/* <option value="Beauty and health care">
                  Beauty and health care
                </option> */}
                <option value="Domestic Appliances">Domestic Appliances</option>
                {/* <option value="Sports and gym">Sports and gym</option> */}
                {/* <option value="Watches & Jewellery">Watches & Jewellery</option> */}
                <option value="Lifestyle & outfits">Lifestyle & outfits</option>
                <option value="Stationaries">Stationaries</option>
                <option value="Ship orders">Ship orders</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {info.length !== 0 ? (
        <div className="container-fluid bg-grey">
          <div className="container pt-40 pb-60 search-card-list">
            <div className="row">
              {/* Pass the info state to frame function */}
              {sliceArray.map((data, querySnapshot) => (
                <Datapage
                  builder_project_img={data.product_main_img}
                  builder_status={data.product_delivery_status}
                  builder_address={data.product_highlights.Price_range}
                  builder_id={data.docId}
                  project_name={data.product_name}
                  project_city={data.city}
                  project_name_keyword={data.project_name_keyword}
                  full_data={data}
                />
              ))}
            </div>
            <div className="row pt-40">
              <div className="col-md-12">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={info.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {(info.length === 0) & (loadData === true) ? (
        <div className="container-fluid bg-grey p-0">
          <div className="container pt-40 pb-60 search-card-list">
            <div className="row">
              <div className="col-md-12 text-center p-5">
                {/* <img src={loadIcon} width="50px" height="50px" /> */}
              </div>
            </div>
          </div>
          {/* <CityPageFreq /> */}
          <Footer />
        </div>
      ) : null}

      {(info.length === 0) & (noData === true) ? (
        <div className="container-fluid bg-grey p-0">
          <div className="container pt-40 pb-60 search-card-list">
            <div className="row">
              <div className="col-md-12 text-center">
                <img src={norecord} className="norecord" />
              </div>
            </div>
          </div>
          {/* <CityPageFreq />
          <Footer /> */}
        </div>
      ) : null}

      {/* <div>{info.length > 0 ? <CityPageFreq /> : null}</div> */}
      <div>{info.length > 0 ? <Footer /> : null}</div>
    </div>
  );
}
// Main function end
// Frame function recall
const Datapage = ({
  builder_project_img,
  builder_status,
  builder_address,
  builder_id,
  project_name,
  project_name_keyword,
  project_city,
  full_data,
}) => {
  const navigate = useNavigate();
  const [showB, setShowB] = useState(false);

  const toggleShowB = () => setShowB(!showB);
  // groupby function create

  const goToPosts = (value, p_name, type, category) => {
    navigate(
      `/product-details/${value}`,

      { state: { id: value.docId, type: type, category: category } }
    );
    window.location.reload();
  };

  function cartAdd(doc_id, data) {
    let addData = {
      quantity: 1,
      product: data,
      customer_details: {
        custom_num: localStorage.getItem("customerNumber"),
        custom_mail: localStorage.getItem("customerEmail"),
        custom_id: localStorage.getItem("customerId"),
      },
    };
    db.collection("add_cart")
      .doc(doc_id + localStorage.getItem("customerNumber"))
      .set(addData)
      .then(() => {
        console.log("Document successfully written!");
        setShowB(true);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }
  // Fetching images set an alt image name
  // var httpsReference_project_logo = storage.refFromURL(builder_project_img);
  // let alt_image_name = httpsReference_project_logo.name;
  // Fetching images set an alt image name end
  return (
    <div className="col-md-4 pt-40 nthclr">
      <Box sx={{ minWidth: 175, backgroundColor: "greenyellow" }}>
        <Card>
          <React.Fragment>
            <CardContent
              style={{ padding: "0", border: "none", width: "100%" }}
              className="pointer"
            >
              <div className="relative">
                <div className="image-hover">
                  <CardMedia
                    onClick={() =>
                      goToPosts(
                        builder_id,
                        project_name_keyword,
                        full_data.vendor_area,
                        full_data.property_category
                      )
                    }
                    component="img"
                    className="searchFilterCardListImg"
                    image={builder_project_img}
                    // alt={alt_image_name}
                  ></CardMedia>
                </div>
                <div className="content-sep-top">
                  <h6>{project_name}</h6>
                  <div className="d-flex pt-1">
                    {/* <ReactSVG src={locationmarker} /> */}
                    <p style={{ marginBottom: "0", marginLeft: "5px" }}>
                      <b>INR {builder_address}</b>
                    </p>
                    <button
                      onClick={() => cartAdd(full_data.docId, full_data)}
                      style={{ marginLeft: "6%" }}
                    >
                      Add cart
                    </button>
                  </div>
                  <ToastContainer className="p-3" position={"bottom-end"}>
                    <Toast show={showB}>
                      <Toast.Header
                        style={{
                          backgroundColor: "darkcyan",
                          color: "white",
                        }}
                        onClick={toggleShowB}
                        closeButton={true}
                      >
                        <img
                          src="holder.js/20x20?text=%20"
                          className="rounded me-2"
                          alt=""
                        />
                        <strong className="me-auto">Add to cart</strong>
                        <small>
                          <img
                            src="https://img.freepik.com/premium-vector/hand-drawn-green-icon-red-backdrop-checkmark-right-vector-icon-hand-drawn_194782-807.jpg?w=2000"
                            height={"30px"}
                          />
                        </small>
                      </Toast.Header>
                      <Toast.Body>
                        Your product successfully move to add cart.
                      </Toast.Body>
                    </Toast>
                  </ToastContainer>
                </div>
                {/* <div className="projStatusbtn-absolute">
                  <Button className="projStatusbtn nthbg">
                    {builder_status}
                  </Button>
                </div> */}
              </div>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </div>
  );
};

export default CategoryWithCityPage;
