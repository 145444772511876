import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
import "../css/signIn.css";
import Logo from "../images/sailors_logo.jpeg";
import { Link, useNavigate } from "react-router-dom";
import db from "../firebaseConfig";
import firebase from "firebase";
import { Button, Modal } from "react-bootstrap";

function Register() {
  const navigation = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [pass, setPass] = useState("");
  const [lastNum, setLastNum] = useState("");
  const [emailAlert, setEmailAlert] = useState("");
  const [mobAlert, setMobAlert] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    navigation("/login");
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchOrderId();
  }, []);
  function fetchOrderId() {
    db.collection("Users")
      .where("userNum", ">", 0)
      .orderBy("userNum", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let data = doc.data();
          setLastNum(data.userNum);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

  async function create() {
    const userNameDoc = await db
      .collection("Users")
      .where("userEmail", "==", email)
      .get();
    const userNumber = await db
      .collection("Users")
      .where("userMob", "==", mobNo)
      .get();
    if (!userNameDoc.empty) {
      console.log("mail");
      document.getElementById("mail-alert").style.display = "block";
      setEmailAlert("Enter email is already exist");
    } else if (!userNumber.empty) {
      console.log("number");
      document.getElementById("mob-alert").style.display = "block";
      setMobAlert("Enter mobile number is already exist");
    } else {
      document.getElementById("mail-alert").style.display = "none";
      document.getElementById("mob-alert").style.display = "none";
      let data = {
        userName: name,
        userEmail: email,
        userMob: mobNo,
        userNum: lastNum + 1,
        customerId: "scc-" + parseInt(lastNum + 1),
      };
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, pass)
        .then(async () => {
          // Signed in
          await db.collection("Users").doc().set(data);
          handleShow();
        });
    }
  }
  return (
    <MDBContainer className="my-5 gradient-form">
      <MDBRow>
        <MDBCol col="6" className="mb-5">
          <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
              <h4 class="mb-4">We are not just a company</h4>
              <p class="small mb-0">
                Seafarerschoice is a private limited entity, registered under
                Ministry of Corporate Affairs, India,Started by group of
                sailors, in order to fulfill shippers demand.
              </p>
            </div>
          </div>
        </MDBCol>
        <MDBCol col="6" className="mb-5">
          <div className="d-flex flex-column ms-5">
            <div className="text-center">
              <img src={Logo} style={{ width: "185px" }} alt="logo" />
              <h4 className="mt-1 mb-5 pb-1">We are the Seafarerschoice</h4>
            </div>

            <p>Please create your account</p>
            <label>User name</label>
            <MDBInput
              wrapperClass="mb-4"
              id="user-name"
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
            <p
              id="user-alert"
              style={{ display: "none" }}
              className="alert-red"
            >
              Please enter a user name
            </p>
            <label>Mobile number</label>
            <MDBInput
              wrapperClass="mb-4"
              id="mobile-number"
              type="number"
              size="10"
              onChange={(e) => setMobNo(e.target.value)}
            />
            <p id="mob-alert" style={{ display: "none" }} className="alert-red">
              {mobAlert}
            </p>
            <label>Email</label>
            <MDBInput
              wrapperClass="mb-4"
              id="form1"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <p
              id="mail-alert"
              style={{ display: "none" }}
              className="alert-red"
            >
              {emailAlert}
            </p>
            <label>Password</label>
            <MDBInput
              wrapperClass="mb-4"
              id="form2"
              type="password"
              onChange={(e) => setPass(e.target.value)}
            />
            <p
              id="pass-alert"
              style={{ display: "none" }}
              className="alert-red"
            >
              Please enter a password
            </p>

            <div className="text-center pt-1 mb-5 pb-1">
              <button
                className="mb-4 w-100 gradient-custom-2"
                onClick={() => create()}
              >
                Sign up
              </button>
              <a className="text-muted" href="/forgot-password">
                Forgot password?
              </a>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
              <p className="mb-0">Already have an account?</p>
              <Link to="/login">
                <MDBBtn outline className="mx-2" color="danger">
                  Signin
                </MDBBtn>
              </Link>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "green" }}>Welcome !!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          you're successfully joined on seaFearschoice and login to make a
          order!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </MDBContainer>
  );
}

export default Register;
