import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
import "../css/signIn.css";
import Logo from "../images/sailors_logo.jpeg";
import { Link, useNavigate } from "react-router-dom";
import firebase from "firebase";
import db, { auth } from "../firebaseConfig";

function Login() {
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  function login() {
    try {
      const res = auth
        .signInWithEmailAndPassword(email, pass)
        .then((response) => {
          console.log("login success", response.user.email);
          if (response.user !== "") {
            console.log("call success");
            // auth.login(response.user.email);
            localStorage.setItem("login", "true");
            localStorage.setItem("email", email);
            // naviga(
            //   `/dashboard`)
            db.collection("Users")
              .where("userEmail", "==", email)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  let data = doc.data();
                  localStorage.setItem("customerNumber", data.userMob);
                  localStorage.setItem("customerEmail", data.userEmail);
                  localStorage.setItem("customerId", data.customerId);
                  setTimeout(() => {
                    navigation("/home");
                  }, 1000);
                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });
          } else {
            // setShow(true)
            console.log("call failure");
          }
        })
        .catch((err) => {
          console.log("err", err);
          // setShow(true);
        });
    } catch (error) {}
  }
  return (
    <MDBContainer className="my-5 gradient-form">
      <MDBRow>
        <MDBCol col="6" className="mb-5">
          <div className="d-flex flex-column ms-5">
            <div className="text-center">
              <img src={Logo} style={{ width: "185px" }} alt="logo" />
              <h4 className="mt-1 mb-5 pb-1">We are the Seafarerschoice</h4>
            </div>

            <p>Please login to your account</p>

            <label>Email</label>
            <MDBInput
              wrapperClass="mb-4"
              id="form1"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Password</label>
            <MDBInput
              wrapperClass="mb-4"
              id="form2"
              type="password"
              onChange={(e) => setPass(e.target.value)}
            />

            <div className="text-center pt-1 mb-5 pb-1">
              <button
                className="mb-4 w-100 gradient-custom-2"
                onClick={() => login()}
              >
                Sign in
              </button>
              <a className="text-muted" href="/forgot-password">
                Forgot password?
              </a>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
              <p className="mb-0">Don't have an account?</p>
              <Link to="/create-account">
                <MDBBtn outline className="mx-2" color="danger">
                  Signup
                </MDBBtn>
              </Link>
            </div>
          </div>
        </MDBCol>

        <MDBCol col="6" className="mb-5">
          <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
            <div className="text-white px-3 py-4 p-md-5 mx-md-4 side-content">
              <h4 class="mb-4">We are not just a company</h4>
              <p class="small mb-0">
                Seafarerschoice is a private limited entity, registered under
                Ministry of Corporate Affairs, India,Started by group of
                sailors, in order to fulfill shippers demand.
              </p>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Login;
