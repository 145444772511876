import React from "react";
import SailorsPrivacy from "../images/sailors-privacy.png";

function Services() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-3"></div>
        <div className="col-2">
          <img
            style={{ marginRight: "5%" }}
            src={SailorsPrivacy}
            height="80px"
          ></img>
        </div>
        <div className="col-6 mt-5" style={{ marginLeft: "5%" }}>
          <h3 style={{ color: "red" }}>OUR SERVICES</h3>
        </div>
      </div>
      <div className="row mt-5">
        <div>
          <strong>
            <u>
              <h6>We deliver to the major ports in India,</h6>
            </u>
          </strong>
          <li>Chennai-Ennore</li>
          <li>Tutucorin</li>
        </div>
      </div>
    </div>
  );
}

export default Services;
