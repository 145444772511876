import React from "react";
import SailorsPrivacy from "../images/sailors-privacy.png";

function Policy() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-3"></div>
        <div className="col-2">
          <img src={SailorsPrivacy} height="100px"></img>
        </div>
        <div className="col-6 mt-5">
          <h3 style={{ color: "red" }}>
            PRIVACY POLICY & TERMS AND CONDITIONS
          </h3>
        </div>
      </div>
      <p>Last updated: February 14, 2023</p>
      <p>
        This privacy policy describes our policies and procedures on the
        collection, use and disclosure of your information when you use the
        service and tells you about your privacy rights and how the law protects
        you.
      </p>
      <p>
        We use your personal data to provide and improve the service. By using
        the service, you agree to the collection and use of information in
        accordance with this privacy policy.
      </p>
      <h6>Interpretation and definitions</h6>
      <h6>Interpretation</h6>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h6>Definitions</h6>
      <p>For the purposes of this privacy policy:</p>
      <li>
        account means a unique account created for you to access our service or
        parts of our service.
      </li>
      <li>
        company (referred to as either &quot;the company&quot;, &quot;we&quot;,
        &quot;us&quot; or &quot;our&quot; in this agreement) refers to
        seafarerschoice.pvt.ltd, seafarerschoice HQ, 116, main road,
        alwarkurichi- 627412.
      </li>
      <li>
        cookies are small files that are placed on your computer, mobile device
        or any other device by a website, containing the details of your
        browsing history on that website among its many uses.
      </li>
      <li>country refers to: india</li>
      <li>
        device means any device that can access the service such as a computer,
        a smart phone or a digital tablet.
      </li>
      <li>
        personal data is any information that relates to an identified or
        identifiable individual.
      </li>
      <li>service refers to the website.</li>
      <li>
        service provider means any natural or legal person who processes the
        data on behalf of the company. It refers to third-party companies or
        individuals employed by the company to facilitate the service, to
        provide the service on behalf of the company, to perform services
        related to the service or to assist the company in analysing how the
        service is used.
      </li>
      <li>
        third-party social media service refers to any website or any social
        network website through which a user can log in or create an account to
        use the service.
      </li>
      <li>
        usage data refers to data collected automatically, either generated by
        the use of the service or from the service infrastructure itself (for
        example, the duration of a page visit).
      </li>
      <li>
        website refers to the sailor&#39;s choice, accessible from{" "}
        <a href="http://www.thesailorschoice.com">
          http://www.thesailorschoice.com
        </a>
      </li>
      <li>
        you means the individual accessing or using the service, or the company,
        or other legal entity on behalf of which such individual is accessing or
        using the service, as applicable.
      </li>
      <h6>Collecting and using your personal data</h6>
      <h6>Types of data collected</h6>
      <h6>Personal data</h6>
      <p>
        While using our service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you. Personally identifiable information may include, but is
        not limited to:
      </p>
      <p>
        <li>email address</li>
        <li>first name and last name and rank</li>
        <li>phone number </li>
        <li>vessel name / identity number (IMO number)</li>
        <li>shipping company / manager/ PIC name (order for ship ) </li>
        <li>usage data</li>
      </p>
      <h6>Usage data</h6>
      <p>Usage data is collected automatically when using the service.</p>
      <p>
        Usage data may include information such as your device&#39;s internet
        protocol address (e.g. Ip address), browser type, browser version, the
        pages of our service that you visit, the time and date of your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        When you access the service by or through a mobile device, we may
        collect certain information automatically, including, but not limited
        to, the type of mobile device you use, your mobile device unique id, the
        IP address of your mobile device, your mobile operating system, the type
        of mobile internet browser you use, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        We may also collect information that your browser sends whenever you
        visit our service or when you access the service by or through a mobile
        device.
      </p>
      <h6>Information from third-party social media services</h6>
      <p>
        The company allows you to create an account and log in to use the
        service through the following third-party social media services:
      </p>
      <li>google</li>
      <li>facebook</li>
      <li>twitter</li>
      <li>linked in, etc.,</li>
      <p>
        If you decide to register through or otherwise grant us access to a
        third-party social media service, we may collect personal data that is
        already associated with your third-party social media service&#39;s
        account, such as your name, your email address, your activities or your
        contact list associated with that account.
      </p>
      <p>
        You may also have the option of sharing additional information with the
        company through your third- party social media service&#39;s account. If
        you choose to provide such information and personal data, during
        registration or otherwise, you are giving the company permission to use,
        share, and store it in a manner consistent with this privacy policy.
      </p>
      <h6>Changes to this privacy policy</h6>
      <p>
        We may update our privacy policy from time to time. We will notify you
        of any changes by posting the new privacy policy on this page.
      </p>
      <p>
        We will let you know via email and/or a prominent notice on our service,
        prior to the change becoming effective and update the &quot;last
        updated&quot; date at the top of this privacy policy.
      </p>
      <p>
        You are advised to review this privacy policy periodically for any
        changes.
      </p>
      <p>
        Changes to this privacy policy are effective when they are posted on
        this page.
      </p>
      <h6>Contact us</h6>
      <p>
        If you have any questions about this privacy policy, you can contact us:
      </p>
      <p>* by visiting this page on our website:</p>
      <a href="http://www.thesailorschoice.com">
        http://www.thesailorschoice.com
      </a>
    </div>
  );
}

export default Policy;
