// all pages connected are Routing in this js file
// import section start
import React from "react";
import ReactDOM from "react-dom";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/home";
import "@fontsource/rubik";
import "bootstrap/dist/css/bootstrap.min.css";
import CategoryWithCityPage from "./components/products";
import ReadProjects from "./components/productDetails";
import SignIn from "./components/signIn";
import SignUp from "./components/signUp";
import Orders from "./components/orders";
import CardListPage from "./components/addCard";
import { AuthProvider } from "./components/auth/auth";
import { RequireAuth } from "./components/auth/requireAuth";
import Policy from "./components/privacy-policy";
import Login from "./components/authentication/login";
import Register from "./components/authentication/signup";
import Forgot from "./components/authentication/forgot";
import Account from "./components/account";
import Refund from "./components/refund";
import Services from "./components/services";
import AboutUs from "./components/aboutUs";
import PaymentSuccess from "./components/payment-success";
import Contact from "./components/contact";
import ShippingPlicy from "./components/cancel_and_ship";
import TermsAndConditions from "./components/terms_and_con";
// import firebaseApp from "firebase/app";

// import section end

// main function start
export default function App() {
  //   useEffect(() => {
  //     // listen();
  //     firebaseApp
  //       .auth()
  //       .signInAnonymously()
  //       .then(() => {})
  //       .catch((error) => {
  //         if (error.code === "auth/operation-not-allowed") {
  //         }
  //       });
  //   }, []);

  window.addEventListener("pageshow", function (event) {
    var historyTraversal =
      event.persisted ||
      (typeof window.performance != "undefined" &&
        window.performance.navigation.type === 2);
    if (historyTraversal) {
      // Handle page restore.
      window.location.reload();
    }
  });

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route
              path="products"
              element={
                <RequireAuth>
                  <CategoryWithCityPage />
                </RequireAuth>
              }
            />
            <Route
              path="product-details/:product"
              element={
                <RequireAuth>
                  <ReadProjects />
                </RequireAuth>
              }
            />
            <Route
              path="orders"
              element={
                <RequireAuth>
                  <Orders />
                </RequireAuth>
              }
            />
            <Route
              path="card-list"
              element={
                <RequireAuth>
                  <CardListPage />
                </RequireAuth>
              }
            />
            <Route
              path="my-account"
              element={
                <RequireAuth>
                  <Account />
                </RequireAuth>
              }
            />
            <Route
              path="service-area"
              element={
                <RequireAuth>
                  <Services />
                </RequireAuth>
              }
            />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="order-status" element={<PaymentSuccess />} />
            <Route path="contact-us" element={<Contact />} />
            <Route
              path="cancellation-and-shipping"
              element={<ShippingPlicy />}
            />
            <Route
              path="terms_and_conditions"
              element={<TermsAndConditions />}
            />
          </Route>
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="privacy-policy" element={<Policy />} />
          <Route path="refund-policy" element={<Refund />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<Forgot />} />
          <Route path="create-account" element={<Register />} />
          <Route path="web-hook-url" />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

// main function end
ReactDOM.render(<App />, document.getElementById("root"));
