import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import "./css/update-style.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import aboutUsImg from "../images/about-us.jpeg";
import db from "./firebaseConfig";
import sailorsLogo from "./images/sailors_logo.jpeg";
import { useEffect } from "react";
import Footer from "./Footer";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath:
      "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    label: "Bird",
    imgPath:
      "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    label: "Bali, Indonesia",
    imgPath:
      "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250",
  },
  {
    label: "Goč, Serbia",
    imgPath:
      "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  },
];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [imageCarosuel, setImageCarosuel] = React.useState([]);
  const [name, setName] = React.useState("");
  const [mobNo, setMobNo] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [load, setLoad] = React.useState(true);

  const maxSteps = images.length;

  useEffect(() => {
    // if (load === true) {
    //   setLoad(false);
    // }
    fetchData();
  }, []);

  const fetchData = () => {
    db.collection("homePageCarosuel")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let data = doc.data();
          setImageCarosuel(data.images);
        });
      });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div>
      <Box sx={{ maxWidth: "100%" }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {imageCarosuel.map((step, index) => (
            <div key={index}>
              {/* {Math.abs(activeStep - index) <= 2 ? ( */}
              <Box
                component="img"
                sx={{
                  height: 375,
                  display: "block",
                  maxWidth: "100%",
                  overflow: "hidden",
                  width: "100%",
                }}
                src={step}
                // alt={step}
              />
              {/* ) : null} */}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Box>
      <Container style={{ marginTop: "5%" }}>
        <Row>
          <h2 className="text-center">KEY FEATURES</h2>
          <Col>
            <div class="card" style={{ marginLeft: "3%", marginRight: "3%" }}>
              <img
                src="https://static.thenounproject.com/png/586283-200.png"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <p class="card-text">
                  This website makes Easy for shippers like you
                </p>
              </div>
            </div>
          </Col>
          <Col>
            <div class="card" style={{ marginLeft: "3%", marginRight: "3%" }}>
              <img
                src="https://cdn-icons-png.flaticon.com/512/2126/2126961.png"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <p class="card-text">Order where and when you want.</p>
              </div>
            </div>
          </Col>
          <Col>
            <div class="card" style={{ marginLeft: "3%", marginRight: "3%" }}>
              <img
                src="https://www.svgrepo.com/show/9324/secure-payment.svg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <p class="card-text">
                  We work with a secure payment service provider.
                </p>
              </div>
            </div>
          </Col>
          <Col>
            <div class="card" style={{ marginLeft: "3%", marginRight: "3%" }}>
              <img
                src="https://thumbs.dreamstime.com/b/delivery-shipment-transport-icon-express-delivery-symbol-truck-icon-truck-lorry-symbol-delivery-shipment-transport-icon-207683561.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <p class="card-text">
                  Track your parcel from the moment the order is placed
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <div
          class="container-fluid d-flex justify-content-center"
          style={{ marginTop: "5%", backgroundColor: "#3b4a6b" }}
        >
          <div class="row">
            <div class="col-sm-6">
              <img
                src={sailorsLogo}
                style={{ marginLeft: "8%" }}
                height="330px"
                alt=""
              />
            </div>
            <div class="col-sm-6 text-center" style={{ marginTop: "7%" }}>
              <div>
                <h3 className="h3-tag-white">About Us</h3>
              </div>
              <div>
                <p className="p-tag-white">
                  Seafarerschoice is a private limited entity, registered under
                  Ministry of Corporate Affairs, India,Started by group of
                  sailors, in order to fulfill shippers demand, that’s why our
                  company slogan is
                </p>
                <p className="p-tag-white">"FROM US FOR US"</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style={{ marginTop: "7%" }}>
          <b>
            <h3 className="text-center">OUR SERVICES</h3>
          </b>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">On-Board Delivery</h5>
                <p class="card-text">
                  Receive your parcel in time on your vessel! Order on time and
                  will have your package ready when you arrive in port. We
                  always deliver your order on time and before your ETD.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Choose your port</h5>
                <p class="card-text">
                  We deliver to the major ports in India,
                  <a href="/service-area"> see full list</a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Customs Clearance</h5>
                <p class="card-text">
                  We declare everything to Customs for you. This is faster and
                  easier. And it saves you time!
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Customer Support</h5>
                <p class="card-text">
                  Do you need help ordering, with your payment or the delivery?
                  Do you have a problem with your order? Look at our or contact
                  for help.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container" style={{ marginTop: "5%" }}>
          <div className="row">
            <div className="col-6">
              <h4>Feedback to submit sailors choice</h4>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Email address
                </label>
                <textarea
                  type="text"
                  class="form-control"
                  onChange={(e) => setMessage(e.target.value)}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Mobile number
                </label>
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => setMobNo(e.target.value)}
                  id="exampleInputPassword1"
                />
              </div>
              <div class="mb-3 form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1">
                  Check me out
                </label>
              </div>
              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </div>
            <div className="col-6">
              <div>
                <img src={sailorsLogo} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default SwipeableTextMobileStepper;
