// Import Firestore database
// This page is projects information show on detailed summary
// import section start
import db from "./firebaseConfig";
import firebaseApp from "firebase/app";
import $ from "jquery";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import React from "react";
import Table from "@material-ui/core/Table";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ReactSVG } from "react-svg";
import Paper from "@material-ui/core/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { purple } from "@mui/material/colors";
import ModalVideo from "react-modal-video";
import Carousel from "react-elastic-carousel";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { CardMedia } from "@mui/material";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import "react-multi-carousel/lib/styles.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@fontsource/rubik";
import "./css/product-details.css";
import locationmarker from "../images/locationmarker.svg";
import filetext from "../images/filetext.svg";
import { Modal } from "react-bootstrap";
import successpopup from "./images/sucesspopup2.svg";
import youtube from "../images/youtube.svg";
import phonecallred from "../images/phonecallred.svg";
import jQuery from "jquery";
import map_marker from "../images/google_maps.svg";
import direction from "../images/direction.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ReactWatsapp from "./js/ReactWhatsapp";
import bottomwhatsapp from "../images/bottomwhatsapp.svg";
import bottommail from "../images/bottommail.svg";
import bottomcall from "../images/bottomcall.svg";
import mailfooter from "../images/mailfooter.svg";
import phonecallfooter from "../images/phonecallfooter.svg";
import loadIcon from "./images/loading_icon.gif";
import "./css/loader.css";
// import {
//   FacebookShareButton,
//   TwitterShareButton,
//   LinkedinShareButton,
// } from "react-share";
// import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import firebase from "firebase";
import Footer from "./Footer";
// import ProjectsPageFreq from "./freqQuestions/projectsPage";
// import ReviewSubmit from "./review";
// import section end
// var storage = firebase.storage();

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];
const breakPoints_top_carosuel = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 1 },
  { width: 1200, itemsToShow: 1 },

  { itemsToShow: 1 },
];

const breakPoints_related_carosuel = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 2 },
  { width: 1200, itemsToShow: 2 },

  { itemsToShow: 2 },
];

const breakPoints_floor_plans = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },

  { itemsToShow: 3 },
];

const containerStyle = {
  height: "450px",
  width: "100%",
};

var split_base_slash;
var year;
var customer_id = "";

let related_data = [];
let related_location_data = [];
// Main function start
const ReadProjects = () => {
  const [info, setInfo] = useState([]);
  const [relatedInfo, setRelatedInfo] = useState([]);
  const [relatedlocationinfo, setRelatedLocationInfo] = useState([]);
  const [loaderState, setLoaderState] = useState(true);
  const [loadData, setLoadData] = useState(false);

  let location = useLocation();
  var title_tab_ = document.title;
  useEffect(() => {
    // setTimeout(() => {
    const d = new Date();
    year = d.getFullYear();
    setInfo([]);
    setLoadData(true);
    Fetchdata();
    // setLoaderState(false);
    // }, 5000);
    console.log(location.state.category);

    return () => {};
  }, [location, title_tab_]);

  // Fetch the required data using the get() method
  // Related projects fetching based on builders
  function related_fetch(category, city) {
    db.collection("Product_Details")
      .where("city", "==", city)
      .where("property_category", "==", category)
      .limit(6)
      .get()
      .then((querySnapshot) => {
        // Loop through the data and store
        querySnapshot.forEach((element) => {
          var data = element.data();
          console.log(data);
          data.docId = element.id;
          setRelatedInfo((arr) => [...arr, data]);
          related_data.push(data);
        });
      });
  }
  // related projects fetching based on location
  function related_location_data_fetch(city) {
    db.collection("Product_Details")
      .where("city", "==", city)
      .limit(10)
      .get()
      .then((querySnapshot) => {
        // Loop through the data and store
        querySnapshot.forEach((element) => {
          var data = element.data();
          console.log(data);
          data.docId = element.id;
          setRelatedLocationInfo((arr) => [...arr, data]);
          related_location_data.push(data);
        });
      });
  }

  function userData() {
    let loginEmail = localStorage.getItem("email");
    db.collection("Users")
      .where("userEmail", "==", loginEmail)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let data = doc.data();
          customer_id = data.customerId;
        });
      });
  }
  // Project details fetching function
  const Fetchdata = () => {
    var remove_hifen;
    var key_word;
    var state_get;
    key_word = location.pathname;
    split_base_slash = key_word.split("/")[2];

    var docRef = db.collection("Product_Details").doc(split_base_slash);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          let data = doc.data();
          data.docId = doc.id;
          setInfo((arr) => [...arr, data]);
          // if(info.length > 0){
          userData();
          related_fetch(doc.data().property_category, doc.data().city);
          related_location_data_fetch(doc.data().city);
          setLoadData(false);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  // Navigate function call in footer
  const navigate = useNavigate();
  function navigation_app(value) {
    var trim_data_lower_case = value.toLowerCase();
    navigate(`/city/${trim_data_lower_case}`, { state: { id: value } });
    window.location.reload();
  }

  function staticPageClick(paths) {
    navigate("/" + paths);
    window.location.reload();
  }
  // Display the result on the page

  return (
    <div>
      {/* {mmodalShow===true ? (
    <div className="app">
    <div class="loader"></div>
    </div>): null} */}
      {/* <div className="container-fluid bg-grey p-0">
        <div className="row">
            <div className="col-md-12"> */}
      {(info.length === 0) & (loadData === true) ? (
        <div>
          <div className="col-md-12 text-center p-5">
            <img
              src={loadIcon}
              className="project-details-load"
              width="50px"
              height="50px"
            />
          </div>
        </div>
      ) : null}
      {/* </div>
          </div>
      </div> */}
      <div>
        {info.map((data, querySnapshot) => (
          <Frame
            // about={data.about}
            // address_get={data.Address}
            top_carosuel_image={data.top_carosuel_images}
            vendor_details={data.vendor_details}
            // builder_contact={data.contact_detaills}
            builder_highlights={data.product_highlights}
            // project_floor_plans={data.Project_Images}
            prod_id={data.docId}
            // project_specification={data.Specifications}
            // walkthrough_video_get={data.walkthrough_video}
            project_logo_get={data.product_main_img}
            // project_status_get={data.project_status}
            // related_data={relatedinfo}
            // related_location_data={relatedlocationinfo}
            project_name_get={data.product_name}
            project_overview={data.product_description}
            // amenities_data={data.amenities}
            // zone_data={data.builder_area}
            // city_data={data.city}
            project_msg={data.product_delivery_status}
            full_data={data}
            // location={data.location}
            // project_location={data.project_location}
            // place_id={data.place_id}
            // location_name={data.location_name}
          />
        ))}
        {/* {<footer/>} */}
        {/* {info.length > 0 ? <ProjectsPageFreq /> : null} */}
        <div>{info.length > 0 ? <Footer /> : null}</div>
      </div>
      {/* )} */}
    </div>
  );
};

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  borderRadius: "100px",
  lineHeight: 1.5,
  backgroundColor: "#0063cc",
  borderColor: "#0063cc",
  fontFamily: ["Rubik"].join(","),
  "&:hover": {
    backgroundColor: "#0069d9",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "#E9341F",
  borderRadius: "100px",
  // '&:hover': {
  //   backgroundColor: purple[700],
  // },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// Frame function recall
const Frame = ({
  // about,
  // address_get,
  project_status_get,
  vendor_details,
  // walkthrough_video_get,
  // project_floor_plans,
  builder_contact,
  project_logo_get,
  top_carosuel_image,
  builder_highlights,
  prod_id,
  project_specification,
  // related_data,
  // amenities_data,
  project_overview,
  project_name_get,
  // city_data,
  // zone_data,
  // related_location_data,
  project_msg,
  full_data,
  // location,
  // project_location,
  // place_id,
  // location_name,
}) => {
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [namePass, setNamePass] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShowhome, setModalShowHome] = useState(false);
  const [modalShowBrochure, setModalShowBrochure] = useState(false);
  const [modalShowUnit, setModalShowUnit] = useState(false);
  const [modalShowVideo, setModalShowVideo] = useState(false);
  const [selecttype, setSelecttype] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [placeHolder, setPlaceHolder] = useState("");
  const [counter, setcounter] = useState("");
  const [resendtime, setResendTime] = useState(true);
  const [resendtimeotp, setResendTimeotp] = useState(false);
  const [changepoint, setChangePoint] = useState(false);
  const [downloadTimer, setDownloadTimer] = useState(false);
  const [rightSideContent, setRightSideContent] = useState("block");
  const [showB, setShowB] = useState(false);

  const toggleShowB = () => setShowB(!showB);

  //Placeholder updated
  useEffect(() => {
    setPlaceHolder("Hello , I am Interested in " + project_name_get);
  }, []);

  function addCart(doc_id, data) {
    let addData = {
      product: data,
      custom_num: localStorage.getItem("customerNumber"),
    };
    db.collection("add_cart")
      .doc(doc_id + localStorage.getItem("customerNumber"))
      .set(addData)
      .then(() => {
        console.log("Document successfully written!");
        setShowB(true);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  async function feedback_store() {
    var name_get = document.getElementById("name_data").value;
    setNamePass(name_get);
    var number_get = document.getElementById("mobile_number_data").value;
    var mail_get = document.getElementById("mail_data").value;
    var feedback_get = document.getElementById("intrested_data").value;
    var year = new Date().getFullYear();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    var month_get = month[d.getMonth()];
    const today = d.getDate();

    const data = {
      name: name_get,
      mobile_number: number_get,
      email: mail_get,
      address: feedback_get,
      vendor_details: {
        vendor_name: vendor_details.vendor_name,
        contact: vendor_details.contact_details,
        address: vendor_details.Address,
      },
      year: year,
      date: today,
      month: month_get,
      product_name: project_name_get,
      product_id: prod_id,
      product_price: builder_highlights.Price_range,
      product_image: project_logo_get,
      // Address: address_get,
      timestamp: d,
      customerId: customer_id,
      value: 0,
    };

    // Add a new document in collection "cities" with ID 'LA'
    await db.collection("Orders").doc().set(data);
    setModalShow(true);
    empty_form();
    setTimeout(function refreshPage() {
      window.location.reload();
    }, 1000);
  }
  function empty_form() {
    document.getElementById("name_data").value = "";
    document.getElementById("mobile_number_data").value = "";
    document.getElementById("mail_data").value = "";
    document.getElementById("intrested_data").value = "";
    document.getElementById("mobile_number_otp").value = "";

    document.getElementById("intrest").style.display = "none";
    document.getElementById("mobile_number").style.display = "none";
    document.getElementById("name").style.display = "none";
    document.getElementById("mail").style.display = "none";
  }

  function brochure_request() {
    setModalShowBrochure(true);
  }

  function configureCaptcha() {
    window.recaptchaVerifier = new firebaseApp.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmit();
        },
        defaultCountry: "IN",
      }
    );
  }
  function configureCaptchabrochure() {
    window.recaptchaVerifier = new firebaseApp.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmitbrochure();
        },
        defaultCountry: "IN",
      }
    );
  }
  function configureCaptchaUnit() {
    window.recaptchaVerifier = new firebaseApp.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmitUnit();
        },
        defaultCountry: "IN",
      }
    );
  }
  function configureCaptchaotp() {
    window.recaptchaVerifier = new firebaseApp.auth.RecaptchaVerifier(
      resendtimeotp
        ? "recaptcha-container-resend"
        : "recaptcha-container-resends",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmitotp();
          window.recaptchaWidgetId = response;
        },
        "expired-callback": (check) => {},
        defaultCountry: "IN",
      }
    );
  }

  async function onSignInSubmitotp() {
    var number_get;
    if (changepoint === "1") {
      setTextInput(true);
      number_get = document.getElementById("mobile_number_data").value;
    } else if (changepoint === "2") {
      setTextInputBrochure(true);

      number_get = document.getElementById("brochure_mobile_number_data").value;
    } else if (changepoint === "3") {
      setTextInputUnit(true);
      number_get = document.getElementById("unit_mobile_number_data").value;
    }
    configureCaptchaotp();

    const phoneNumber = "+91" + number_get;
    const appVerifier = window.recaptchaVerifier;
    firebaseApp
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {});
  }

  async function onSignInSubmit() {
    var number_get = document.getElementById("mobile_number_data").value;

    await configureCaptcha();
    const phoneNumber = "+91" + number_get;
    const appVerifier = window.recaptchaVerifier;
    firebaseApp
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
      });
  }
  function onSignInSubmitbrochure() {
    var number_get = document.getElementById(
      "brochure_mobile_number_data"
    ).value;
    configureCaptchabrochure();
    const phoneNumber = "+91" + number_get;
    const appVerifier = window.recaptchaVerifier;
    firebaseApp
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
      });
  }
  function onSignInSubmitUnit() {
    var number_get = document.getElementById("unit_mobile_number_data").value;
    configureCaptchaUnit();
    const phoneNumber = "+91" + number_get;
    const appVerifier = window.recaptchaVerifier;
    firebaseApp
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
      });
  }

  async function Otp_verify_check(status) {
    document.getElementById("otp-text-box").style.display = "none";
    setTextInput(false);
    await send_feedback_msg();
    feedback_store();
  }

  async function Otp_verify_check_home(status) {
    document.getElementById("brochure_otp").style.display = "none";
    setTextInputBrochure(false);
    await brochure_mail_send_home();
    feedback_store();

    await setModalShowHome(false);
  }
  async function Otp_verify_check_brochure(status) {
    document.getElementById("brochure_otp").style.display = "none";
    setTextInputBrochure(false);
    await brochure_mail_send();
    setTimeout(() => {
      feedback_brochure_store();
    }, 2000);
  }
  async function Otp_verify_check_Unit(status) {
    document.getElementById("unit_otp").style.display = "none";
    await unit_mail_msg();
    setTextInputUnit(false);
  }
  async function onSubmitOTP() {
    var number_get = document.getElementById("mobile_number_otp").value;

    const code = number_get;
    window.confirmationResult
      .confirm(code)
      .then(async (result) => {
        // User signed in successfully.
        var status = "success";
        Otp_verify_check(status);
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        document.getElementById("otp-text-box").style.display = "block";
      });
  }
  async function home_onSubmitOTP() {
    var number_get = document.getElementById("brochure_otp_data").value;

    const code = number_get;
    window.confirmationResult
      .confirm(code)
      .then(async (result) => {
        // User signed in successfully.
        const user = result.user;
        var status = "success";
        Otp_verify_check_home(status);
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        document.getElementById("brochure_otp").style.display = "block";
      });
  }

  async function Resendotp() {
    setResendTimeotp(!resendtimeotp);
    setResendTime(true);
    onSignInSubmitotp();

    var timeleft = 60;
    downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        setResendTime(false);
        setcounter("");
      }
      setcounter(timeleft);
      timeleft -= 1;
    }, 1000);
  }
  async function onSubmitOTPbrochure() {
    var number_get = document.getElementById("brochure_otp_data").value;

    const code = number_get;
    window.confirmationResult
      .confirm(code)
      .then(async (result) => {
        // User signed in successfully.
        var status = "success";
        Otp_verify_check_brochure(status);
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        document.getElementById("brochure_otp").style.display = "block";
      });
  }
  async function onSubmitOTPUnit() {
    var number_get = document.getElementById("unit_otp_data").value;

    const code = number_get;
    window.confirmationResult
      .confirm(code)
      .then(async (result) => {
        // User signed in successfully.
        var status = "success";
        Otp_verify_check_Unit(status);
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        document.getElementById("unit_otp").style.display = "block";
      });
  }

  async function feedback_brochure_validate() {
    if (document.getElementById("brochure_name_data").value === "") {
      document.getElementById("brochure_name").style.display = "block";
      document.getElementById("brochure_mail").style.display = "none";
      document.getElementById("brochure_mobile_number").style.display = "none";
    } else if (
      document.getElementById("brochure_mobile_number_data").value === ""
    ) {
      document.getElementById("brochure_mobile_number").style.display = "block";
      document.getElementById("brochure_mail").style.display = "none";
      document.getElementById("brochure_name").style.display = "none";
    } else if (document.getElementById("brochure_mail_data").value === "") {
      document.getElementById("brochure_mail").style.display = "block";
      document.getElementById("brochure_mobile_number").style.display = "none";
      document.getElementById("brochure_name").style.display = "none";
    } else {
      onSignInSubmitbrochure();
      setTextInputBrochure(true);
      var timeleft = 60;
      downloadTimer = setInterval(function () {
        if (timeleft <= 0) {
          clearInterval(downloadTimer);
          setResendTime(false);
          setcounter("");
        }
        setcounter(timeleft);
        timeleft -= 1;
      }, 1000);
    }
  }

  async function brochure_mail_send() {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;

    var name_get = document.getElementById("brochure_name_data").value;
    var number_get = document.getElementById(
      "brochure_mobile_number_data"
    ).value;
    var mail_get = document.getElementById("brochure_mail_data").value;
    // var address_get =
    // address_get + "," + city_data + "-" + zone_data + "," + city_data;
    var request_sender = "Brochure";
    // fetch(`https://cgc-mailapi.herokuapp.com/?subject=${
    //   project_name_get + " " + dateTime
    // }&name=${name_get}&mob_no=${number_get}&mail=${mail_get}
    // &builder_name=${
    //   builder_details.builder_name
    // }&contact=${builder_contact}&address=${address_get}&request=${request_sender}`);
  }
  async function brochure_mail_send_home() {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;

    var name_get = document.getElementById("brochure_name_data").value;
    var number_get = document.getElementById(
      "brochure_mobile_number_data"
    ).value;
    var mail_get = document.getElementById("brochure_mail_data").value;
    var request_sender = "Feedback";
    fetch(
      `https://cgc-mailapi.herokuapp.com/?subject=${
        name_get + " " + dateTime
      }&name=${name_get}&mob_no=${number_get}&mail=${mail_get}&request=${request_sender}`
    );
  }

  async function feedback_brochure_store() {
    var name_get = document.getElementById("brochure_name_data").value;
    setNamePass(name_get);
    var number_get = document.getElementById(
      "brochure_mobile_number_data"
    ).value;
    var mail_get = document.getElementById("brochure_mail_data").value;

    var year = new Date().getFullYear();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    var month_get = month[d.getMonth()];
    const today = d.getDate();

    const data = {
      name: name_get,
      mobile_number: number_get,
      email: mail_get,
      year: year,
      month: month_get,
      date: today,
      builder_details: {
        builder_name: vendor_details.builder_name,
        builder_contact: builder_contact,
      },
      project_name: project_name_get,
      // property_address: address_get,
      timestamp: d,
    };

    // Add a new document in collection BrochureNeed
    await db.collection("BrochureNeed").doc().set(data);
    setModalShowBrochure(false);
    setModalShow(true);
    setTimeout(function refreshPage() {
      window.location.reload();
    }, 1000);
  }

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  async function feedback_validate() {
    if (document.getElementById("name_data").value === "") {
      document.getElementById("name_alert").style.display = "block";
      document.getElementById("intrest").style.display = "none";
      document.getElementById("mail").style.display = "none";
      document.getElementById("mobile_number").style.display = "none";
    } else if (document.getElementById("mobile_number_data").value === "") {
      document.getElementById("mobile_number").style.display = "block";
      document.getElementById("intrest").style.display = "none";
      document.getElementById("mail").style.display = "none";
      document.getElementById("name_alert").style.display = "none";
    } else if (document.getElementById("mail_data").value === "") {
      document.getElementById("mail").style.display = "block";
      document.getElementById("intrest").style.display = "none";
      document.getElementById("mobile_number").style.display = "none";
      document.getElementById("name_alert").style.display = "none";
    } else if (document.getElementById("intrested_data").value === "") {
      document.getElementById("intrest").style.display = "block";
      document.getElementById("mobile_number").style.display = "none";
      document.getElementById("name_alert").style.display = "none";
      document.getElementById("mail").style.display = "none";
    } else {
      // setRightSideContent('none');
      // onSignInSubmit();
      // setTextInput(true);
      // var timeleft = 60;
      // downloadTimer = setInterval(function () {
      //   if (timeleft <= 0) {
      //     clearInterval(downloadTimer);
      //     setResendTime(false);
      //   }
      //   setcounter(timeleft);
      //   timeleft -= 1;
      // }, 1000);
      feedback_store();
    }
  }

  async function home_feedback() {
    if (document.getElementById("brochure_name_data").value === "") {
      document.getElementById("brochure_name").style.display = "block";
      document.getElementById("brochure_mail").style.display = "none";
      document.getElementById("brochure_mobile_number").style.display = "none";
    } else if (
      document.getElementById("brochure_mobile_number_data").value === ""
    ) {
      document.getElementById("brochure_mobile_number").style.display = "block";
      document.getElementById("brochure_mail").style.display = "none";
      document.getElementById("brochure_name").style.display = "none";
    } else if (document.getElementById("brochure_mail_data").value === "") {
      document.getElementById("brochure_mail").style.display = "block";
      document.getElementById("brochure_mobile_number").style.display = "none";
      document.getElementById("brochure_name").style.display = "none";
    } else {
      onSignInSubmitbrochure();
      setTextInputBrochure(true);
      var timeleft = 60;
      var downloadTimer = setInterval(function () {
        if (timeleft <= 0) {
          clearInterval(downloadTimer);
          setResendTime(false);
        }
        setcounter(timeleft);
        timeleft -= 1;
      }, 1000);
    }
  }

  async function send_feedback_msg() {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;
    var name_get = document.getElementById("name_data").value;
    var number_get = document.getElementById("mobile_number_data").value;
    var mail_get = document.getElementById("mail_data").value;
    // var address_get_send =
    //   address_get + "," + city_data + "-" + zone_data + "," + city_data;
    var request_sender = "Feedback";
    // fetch(`https://cgc-mailapi.herokuapp.com/?subject=${
    //   project_name_get + " " + dateTime
    // }&name=${name_get}&mob_no=${number_get}&mail=${mail_get}
    // &builder_name=${
    //   builder_details.builder_name
    // }&contact=${builder_contact}&address=${address_get_send}&request=${request_sender}`);
  }

  const navigate_related = useNavigate();

  function related_data_go(val, p_name) {
    // let data = p_name;
    // let data1 = val.trim().split(" ");
    // var trim_data = data1.join("-");
    // var trim_data_lower_case = trim_data.toLowerCase()
    navigate_related(`/projects/${val}`, { state: { id: val } });
    setTimeout(function refreshPage() {
      window.location.reload(false);
    }, 1000);
  }

  function unitdata_request(index_get) {
    setSelecttype(index_get);
    setModalShowUnit(true);
  }
  // function setCurrentLocation() {
  //   // Change current location
  //   window.open(
  //     `https://www.google.com/maps/search/?api=1&query=${location.latitude}%2C${location.longitude}&query_place_id=${place_id}`,
  //     "_blank" // <- This is what makes it open in a new window.
  //   );
  // }
  // function setCurrentdirection() {
  //   // Change current location
  //   window.open(
  //     `https://www.google.com/maps/dir/?api=1&destination=QVB&destination_place_id=${place_id}`,

  //     "_blank" // <- This is what makes it open in a new window.
  //   );
  // }

  function video_request() {
    setModalShowVideo(true);
  }

  function MyVerticallyCenteredVideo(props) {
    setOpen(true);
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Walkthrough Video</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3 pt-0">
          <div className="walk_through">
            <ModalVideo
              channel="youtube"
              autoplay
              isOpen={isOpen}
              // videoId={walkthrough_video_get}
              onClose={() => setOpen(false)}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  async function unit_validate() {
    if (document.getElementById("unit_name_data").value === "") {
      document.getElementById("unit_name").style.display = "block";
      document.getElementById("unit_mail").style.display = "none";
      document.getElementById("unit_mobile_number").style.display = "none";
    } else if (
      document.getElementById("unit_mobile_number_data").value === ""
    ) {
      document.getElementById("unit_mobile_number").style.display = "block";
      document.getElementById("unit_mail").style.display = "none";
      document.getElementById("unit_name").style.display = "none";
    } else if (document.getElementById("unit_mail_data").value === "") {
      document.getElementById("unit_mail").style.display = "block";
      document.getElementById("unit_mobile_number").style.display = "none";
      document.getElementById("unit_name").style.display = "none";
    } else {
      setTextInputUnit(true);

      onSignInSubmitUnit();
      var timeleft = 60;
      downloadTimer = setInterval(function () {
        if (timeleft <= 0) {
          clearInterval(downloadTimer);
          setResendTime(false);
          setcounter("");
        }
        setcounter(timeleft);
        timeleft -= 1;
      }, 1000);
    }
  }

  async function unit_mail_msg() {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;
    var name_get = document.getElementById("unit_name_data").value;
    var number_get = document.getElementById("unit_mobile_number_data").value;
    var mail_get = document.getElementById("unit_mail_data").value;
    // var address_get_send =
    // address_get + "," + city_data + "-" + zone_data + "," + city_data;
    var request_sender = "Unit Details";
    // fetch(`https://cgc-mailapi.herokuapp.com/?subject=${
    //   project_name_get + " " + dateTime
    // }&name=${name_get}&mob_no=${number_get}&mail=${mail_get}
    // &builder_name=${
    //   builder_details.builder_name
    // // }&contact=${builder_contact}&address=${address_get_send}&request=${request_sender}`);

    setTimeout(function refreshPage() {
      unit_store();
    }, 2000);
  }

  async function unit_store() {
    var name_get = document.getElementById("unit_name_data").value;
    setNamePass(name_get);
    var number_get = document.getElementById("unit_mobile_number_data").value;
    var mail_get = document.getElementById("unit_mail_data").value;
    var year = new Date().getFullYear();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    var month_get = month[d.getMonth()];
    const today = d.getDate();

    const data = {
      name: name_get,
      mobile_number: number_get,
      email: mail_get,
      builder_details: {
        builder_name: vendor_details.builder_name,
        builder_contact: builder_contact,
      },
      date: today,
      month: month_get,
      year: year,
      project_name: project_name_get,
      want_unit_type: selecttype,
      // property_address: address_get,
      timestamp: d,
    };

    // Add a new document in collection "cities" with ID 'LA'
    await db.collection("UnitDetailSubmit").doc().set(data);
    setModalShowUnit(false);
    setModalShow(true);
  }
  async function CloseModalUnit() {
    await setModalShowUnit(false);
    if (settextinputunit === true) {
      setTimeout(function refreshPage() {
        window.location.reload();
      }, 1000);
    }

    setTextInputUnit(false);
  }

  async function CloseModalBrochure() {
    await setModalShowBrochure(false);
    if (settextinputbrochure === true) {
      setTimeout(function refreshPage() {
        window.location.reload();
      }, 1000);
    }
    setTextInputBrochure(false);
  }
  async function CloseModalhome() {
    await setModalShow(false);
    if (settextinputbrochure === true) {
      setTimeout(function refreshPage() {
        window.location.reload();
      }, 1000);
    }
    setModalShowHome(false);
  }

  // holds the start time
  var timer;
  jQuery(".project-detail-carousel")
    .on("mousedown", function () {
      timer = setTimeout(function () {}, 2 * 1000);
    })
    .on("mouseup mouseleave", function () {
      clearTimeout(timer);
    });

  const [settextinput, setTextInput] = useState(false);
  const [settextinputbrochure, setTextInputBrochure] = useState(false);
  const [settextinputunit, setTextInputUnit] = useState(false);
  const [href, setHref] = useState("");

  function status_base_get(val) {
    let data = val;
    let data1 = data.trim().split(" ");
    var trim_data = data1.join("-");
    var trim_data_lower_case = trim_data.toLowerCase();
    navigate_related(`/status/${trim_data_lower_case}`);
    window.location.reload();
  }

  useEffect(() => {
    setHref(window.location.href);
    var message_content =
      "Hi! I'm interested in the" +
      " " +
      project_name_get +
      " " +
      // city_data +
      // "! Please share more details. Thanks!";
      setMessage(message_content);
    setNumber("919677051111");
  }, []);

  // project logo to split alt image

  // var httpsReference_project_logo = storage.refFromURL(project_logo_get);
  // let alt_img_project_logo = httpsReference_project_logo.name;

  //   // builder logo to split alt image

  //   var httpsReference_builder_logo = storage.refFromURL(builder_details.builder_logo);
  //   let alt_img_builder_logo = httpsReference_builder_logo.name;

  return (
    <div>
      {/* <div>

      </div> */}

      <div className="container-fluid bg-grey pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="relative project-detail-carousel">
                <Carousel
                  itemsToScroll={1}
                  breakPoints={breakPoints_top_carosuel}
                  enableMouseSwipe={false}
                  // disableArrowsOnEnd={false}
                >
                  {top_carosuel_image.map((img_url) => {
                    // let storage = Storage.storage()
                    // let httpsReference_project_logo = storage.refFromURL(img_url);
                    // let alt_image_name = httpsReference_project_logo.name;
                    return (
                      <React.Fragment>
                        <CardContent style={{ width: "100%" }}>
                          <img
                            // component="img"
                            // // alt={alt_image_name}
                            height="300px"
                            src={img_url}
                            style={{ marginLeft: "20%" }}
                          />
                        </CardContent>
                      </React.Fragment>
                    );
                  })}
                </Carousel>

                {/* <div className="slider-absolute">
            <Button
              style={{
                backgroundColor: "#E9341F",
                borderRadius: "100px",
                boxShadow: "none",
                fontWeight: "400",
              }}
              variant="contained"
              color="success"
              onClick={() => status_base_get(project_status_get)}
            >
              {project_status_get}
            </Button>
          </div>
          {project_msg !== undefined ? (
            <p className="sub-stage">{project_msg}</p>
          ) : (
            ""
          )} */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex res-d-flex">
                {/* {project_logo_get !== "" ? (
                  <div className="ProjFirstSecLeftImage d-flex align-items-center justify-content-center">
                    <img
                      src={project_logo_get}
                      width="100%"
                      // alt={alt_img_project_logo}
                    />
                  </div>
                ) : (
                  ""
                )} */}
                <div>
                  <div className="bottom-social-icon d-flex">
                    <a href="tel:099406 14444" target="_blank">
                      {/* <img src={bottomcall} width="45" height="45" /> */}
                    </a>
                    <a href="mailto:cgchomesllp@gmail.com" target="_blank">
                      {/* <img src={bottommail} width="45" height="45" /> */}
                    </a>
                    {/* <ReactWatsapp
                      element="cgc"
                      number={number}
                      message={message}
                    >
                      <img src={bottomwhatsapp} width="45" height="45" />
                    </ReactWatsapp>{" "} */}
                    {/* <FacebookShareButton url={href}>
                      <FacebookIcon
                        style={{ marginLeft: "5px" }}
                        width="45"
                        height="45"
                        round
                      />
                    </FacebookShareButton>
                    <TwitterShareButton url={href}>
                      <TwitterIcon
                        style={{ marginLeft: "5px" }}
                        width="45"
                        height="45"
                        round
                      />
                    </TwitterShareButton>
                    <LinkedinShareButton url={href}>
                      <LinkedinIcon
                        style={{ marginLeft: "5px" }}
                        width="45"
                        height="45"
                        round
                      />
                    </LinkedinShareButton> */}
                  </div>
                  <h1 className="seo-h mb-3 text-right">{project_name_get}</h1>
                  {/* {address_get.length > 0 ? ( */}
                  <div className="d-flex res-d-flex">
                    <p className="mb-3">
                      <span className="pr-1">
                        <strong>₹</strong>{" "}
                      </span>
                      INR-{builder_highlights.Price_range}
                    </p>
                  </div>
                  {/* ) : (
                    ""
                  )} */}
                  {/* <div className="walk-broc-btn">
                    <ColorButton
                      className="responsive-colorButton"
                      style={{
                        color: "#ffffff",
                        background: "#1F8ACA",
                        boxShadow: "none",
                        fontFamily: "Rubik",
                        fontWeight: "400",
                        fontSize: "14px",
                        height: "30px",
                        lineHeight: "1",
                        textTransform: "capitalize",
                        marginRight: "10px",
                        width: "auto",
                      }}
                      variant="contained"
                      onClick={() => video_request()}
                    >
                      <img src={youtube} width="18px" height="18px" />{" "}
                      <span style={{ paddingLeft: "10px" }}>
                        Walkthrough video
                      </span>
                    </ColorButton>
                    <BootstrapButton
                      className="responsive-bootstrapButton btn_brochure"
                      style={{
                        color: "#ffffff",
                        background: "#E9341F",
                        boxShadow: "none",
                        fontFamily: "Rubik",
                        fontWeight: "400",
                        fontSize: "14px",
                        height: "30px",
                        lineHeight: "1",
                        textTransform: "capitalize",
                        border: "none",
                        width: "auto",
                      }}
                      variant="contained"
                      disableRipple
                      onClick={brochure_request}
                    >
                      {" "}
                      <img src={filetext} width="16px" height="16px" />{" "}
                      <span style={{ paddingLeft: "10px" }}>Brochure</span>
                    </BootstrapButton>
                  </div> */}
                </div>
              </div>
            </div>

            {/* {builder_details.builder_logo !== "" ? ( */}
            {/* <div className="col-md-3">
              <div className="ProjFirstSecRightImage d-flex align-items-center justify-content-center">
                <img
                  // src={builder_details.builder_logo}
                  width="100%"
                  // alt={alt_img_builder_logo}
                />
              </div>
            </div> */}
            {/* ) : (
              ""
            )} */}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-8">
            {/* Configuration Section */}
            {/* {builder_highlights.length > 0 ? ( */}
            {full_data.other_details[0].unit_type !== "" ? (
              <section className="pt-60 pb-40 hr-border-bottom configuration">
                <h4 className="mb-4">
                  <span className="text-underline-blue">
                    {project_name_get}{" "}
                  </span>
                  <span className="text-underline">Configuration</span>
                </h4>
                <table>
                  <tbody>
                    {/* {builder_highlights.RERA_No.length > 0 ? ( */}
                    {full_data.other_details.map((val) => (
                      <tr>
                        <td>
                          <p className="mb-1">{val.unit_type}</p>
                        </td>
                        <td>
                          <p className="mb-1">{val.size}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            ) : null}

            {/* ) : (
              ""
            )}  */}
            {/* Overview Section */}
            {/* {project_overview.length > 0 ? ( */}
            <section className="pt-40 pb-40 hr-border-bottom overview">
              <h4 className="mb-4">
                <span className="text-underline-blue">{project_name_get} </span>
                <span className="text-underline">Overview</span>
              </h4>
              <p className="overview-content">{project_overview}</p>
            </section>
            {/* ) : (
              ""
            )} */}

            {/* Unit type and size Section */}
            {/* {unit_details[0].unit_type.length > 0 ? ( */}
            {/* <section className="pt-40 pb-40 hr-border-bottom unit-type-and-size">
                <h4 className="mb-4">
                  <span className="text-underline-blue">
                    {project_name_get}{" "}
                  </span>
                  <span className="text-underline">Other Modals</span>
                </h4>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    style={{ marginTop: "10px" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Unit Type</TableCell>
                        <TableCell align="center">Size</TableCell>
                        <TableCell align="center">Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {unit_details.map((row, index) => ( 
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {/* <TableCell align="center">{row.unit_type}</TableCell>
                          <TableCell align="center">{row.size}</TableCell> 
                          <TableCell align="center">
                            <button
                              className="btn-table"
                              // onClick={() => unitdata_request(row.unit_type)}
                            >
                              Click here
                            </button>
                          </TableCell>
                        </TableRow>
                      {/* ))} 
                    </TableBody>
                  </Table>
                </TableContainer>
              </section> */}
            {/* ) : (
              ""
            )} */}

            {/* <section className="pt-40 pb-40 hr-border-bottom location">
              <h4 className="mb-4">
                <span className="text-underline-blue">{project_name_get} </span>
                <span className="text-underline">Location</span>
              </h4>

              {/* {location !== undefined ? (
                <div className="map-relative">
                  {/* {project_location.length > 0 ? (
                    <div className="map-absolute">
                      <div className="d-flex">
                        <div>
                          {/* {location_name.length > 0 ? (
                            <p className="map-title-text">{location_name}</p> */}
            {/* ) : (
                            ""
                          )}
                          <p className="map-text">{project_location}</p> 
                          <button
                            className="view-large-map"
                            // className='btn-table'
                            // onClick={() => setCurrentLocation()}
                          >
                            View larger map
                          </button>
                        </div>
                        <div className="pl-10">
                          <button
                            className="btn-direction"
                            // onClick={() => setCurrentdirection()}
                          >
                            <img
                              src={direction}
                              alt="MyPin"
                              style={{ width: "28px" }}
                            />
                            <p className="directions-text m-0">Directions</p>
                          </button>
                        </div>
                      </div>
                    </div>
                  {/* ) : (
                    ""
                  )} 
                  <LoadScript googleMapsApiKey="AIzaSyAtoMk_jYkNxaLfmxCCMm3lkmTtCOqGl5M">
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        // lat: location.latitude,
                        // lng: location.longitude,
                      }}
                      zoom={17}
                    >
                      <Marker
                        position={{
                          // lat: location.latitude,
                          // lng: location.longitude,
                        }}
                        icon={{
                          url: map_marker,
                        }}
                      ></Marker>
                    </GoogleMap>
                  </LoadScript>
                </div>
              {/* ) : (
                ""
              )} 
            </section> */}

            {/* Amenities */}
            {/* {amenities_data !== undefined && amenities_data.length > 0 ? ( */}
            {/* <section className="pt-40 pb-10 hr-border-bottom amenities">
                <h4 className="mb-4">
                  <span className="text-underline-blue">
                    {/* {project_name_get}{" "} 
                  </span>
                  <span className="text-underline">Amenities</span>
                </h4>
                <div>
                  <Carousel breakPoints={breakPoints} enableMouseSwipe={false}>
                    {/* {amenities_data.map((amenity) => { */}
            {/* // var httpsReference_project_logo = storage.refFromURL(amenity.img);
                      // let alt_image_name = httpsReference_project_logo.name;
                      // return ( 
                        <React.Fragment>
                          <CardContent className="amenities-card">
                            <CardMedia
                              className="card-icon"
                              component="img"
                              // image={amenity.img}
                              // alt={alt_image_name}
                            />
                            <Typography className="icon-text">
                              {" "}
                              {/* {amenity.content} 
                            </Typography>
                          </CardContent>
                        </React.Fragment>
                      {/* );
                    })} 
                  </Carousel>
                </div>
              </section> */}
            {/* ) : (
              ""
            )} */}

            {/* Specification */}
            {/* {project_specification.length !== undefined &&
            project_specification[0].title !== undefined &&
            project_specification.length > 0 &&
            project_specification[0].title !== "" ? ( */}
            {/* <section className="pt-40 pb-40 hr-border-bottom specification">
                <h4 className="mb-4">
                  <span className="text-underline-blue">
                    {project_name_get}{" "}
                  </span>
                  <span className="text-underline">Specification</span>
                </h4>

                {/* {project_specification.map((element) => ( 
                  <Accordion
                    // expanded={expanded === element}
                    // onChange={handleChange(element)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Typography className="spec-title">
                        {/* {element.title} 
                      </Typography>
                    </AccordionSummary>
                    {/* {element.value.map((element_content) => ( 
                      <AccordionDetails>
                        <Typography className="content">
                          <i className="fa fa-circle bullet-point"></i>
                          {/* <span>{element_content} </span> 
                        </Typography>
                      </AccordionDetails>
                    {/* ))} 
                  </Accordion>
                {/* ))} 
              </section>
            {/* ) : (
              ""
            )} */}

            {/* Related Projects */}
            <section className="pt-40 nthclr relatedprojects hr-border-bottom">
              <h4 className="mb-4">
                <span className="text-underline-blue">
                  Similiar Products of{" "}
                </span>
                <span className="text-underline"> your Interest</span>
              </h4>
              <div>
                {related_data.length > 0 ? (
                  <Carousel
                    breakPoints={breakPoints_related_carosuel}
                    enableMouseSwipe={false}
                  >
                    {related_data.map((value) => {
                      // let httpsReference_project_logo = storage.refFromURL(value.project_img);
                      // let alt_image_name = httpsReference_project_logo.name;
                      return (
                        // <div className='relative' >
                        <React.Fragment>
                          <CardContent
                            onClick={
                              () => related_data_go()
                              // value.project_name_keyword,
                              // value.project_name
                            }
                            style={{ width: "100%" }}
                            className="pointer"
                          >
                            <div className="relative">
                              <div className="image-hover">
                                <CardMedia
                                  component="img"
                                  className="cardListImg"
                                  image={value.product_main_img}
                                  // alt={alt_image_name}
                                />
                              </div>
                              <div className="content-sep-top">
                                <h6>{value.product_name}</h6>
                                <div className="d-flex pt-1">
                                  {/* <ReactSVG src={locationmarker} /> */}
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <b>
                                      INR {value.product_highlights.Price_range}
                                    </b>
                                  </p>
                                </div>
                              </div>
                              {/* <div className="projStatusbtn-absolute">
                                <Button className="projStatusbtn nthbg">
                                  {value.product_delivery_status}
                                </Button>
                              </div> */}
                            </div>
                          </CardContent>
                        </React.Fragment>
                      );
                    })}
                  </Carousel>
                ) : (
                  <p>No record found</p>
                )}
              </div>
            </section>

            {/* Location base projects*/}

            <section className="pt-40 nthclr relatedprojects hr-border-bottom">
              <h4 className="mb-4">
                <span className="text-underline-blue">
                  Other Products from{" "}
                </span>
                <span className="text-underline">Our Services</span>
              </h4>
              <div>
                {related_location_data.length > 0 ? (
                  <Carousel
                    breakPoints={breakPoints_related_carosuel}
                    enableMouseSwipe={false}
                  >
                    {related_location_data.map((value) => {
                      //  let httpsReference_project_logo = storage.refFromURL(value.project_img);
                      //  let alt_image_name = httpsReference_project_logo.name;
                      return (
                        // <div className='relative' >
                        <React.Fragment>
                          <CardContent
                            onClick={
                              () => related_data_go()
                              // value.project_name_keyword,
                              // value.project_name
                            }
                            style={{ width: "100%" }}
                            className="pointer"
                          >
                            <div className="relative">
                              <div className="image-hover">
                                <CardMedia
                                  component="img"
                                  className="cardListImg"
                                  image={value.product_main_img}
                                  // alt={alt_image_name}
                                />
                              </div>
                              <div className="content-sep-top">
                                <h6>{value.product_name}</h6>
                                <div className="d-flex pt-1">
                                  {/* <ReactSVG src={locationmarker} /> */}
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <b>
                                      INR {value.product_highlights.Price_range}
                                    </b>
                                  </p>
                                </div>
                                {/* ) : (
                                  ""
                                )} */}
                              </div>
                              {/* <div className="projStatusbtn-absolute">
                                <Button className="projStatusbtn nthbg">
                                  {value.product_delivery_status}
                                </Button>
                              </div> */}
                            </div>
                          </CardContent>
                        </React.Fragment>
                      );
                    })}
                  </Carousel>
                ) : (
                  <p>No record found</p>
                )}
              </div>
            </section>
            {/* <ReviewSubmit name={project_name_get} keyword={split_base_slash} /> */}
          </div>
          <div className="col-md-4 pt-60">
            <div className="right-side-form">
              <h5 className="m-0">
                <span className="text-underline-blue">Add to cart this </span>
                <span className="text-underline">{project_name_get}</span>
              </h5>
              <div>
                {/* <div
                  className="form-group"
                  style={{ display: rightSideContent }}
                >
                  <label for="yourName" className="pt-0">
                    Your name
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="name_data"
                    aria-describedby="emailHelp"
                    disabled={settextinput ? true : false}
                  />
                  <p
                    style={{ display: "none" }}
                    id="name_alert"
                    className="text-red m-0"
                  >
                    Enter a Name must
                  </p>
                </div>
                <div
                  className="form-group"
                  style={{ display: rightSideContent }}
                >
                  <label for="mobileNumber" className="pt-0">
                    Mobile Number
                  </label>
                  <div id="recaptcha-container"></div>
                  <div id="recaptcha-container-resend"></div>
                  <div id="recaptcha-container-resends"></div>

                  <input
                    className="form-control"
                    id="mobile_number_data"
                    aria-describedby="emailHelp"
                    disabled={settextinput ? true : false}
                    type="text"
                  />
                  <p
                    style={{ display: "none" }}
                    id="mobile_number"
                    className="text-red m-0"
                  >
                    Enter a mobile number must
                  </p>
                </div>
                <div
                  className="form-group"
                  style={{ display: rightSideContent }}
                >
                  <label for="yourMail" className="pt-0">
                    Your mail
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mail_data"
                    value={localStorage.getItem("customerEmail")}
                    disabled
                  />
                  <p
                    style={{ display: "none" }}
                    id="mail"
                    className="text-red m-0"
                  >
                    Enter a mail must
                  </p>
                </div>
                <div
                  className="form-group"
                  style={{ display: rightSideContent }}
                >
                  <label for="yourMail" className="pt-0">
                    Address
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="intrested_data"
                    // placeholder={placeHolder}
                  />
                  <p
                    style={{ display: "none" }}
                    id="intrest"
                    className="text-red m-0"
                  >
                    Enter a message must
                  </p>
                </div> */}

                {/* {settextinput ? (
                  <div>
                    <div className="form-group">
                      <label for="yourMail">
                        Enter the OTP {resendtime ? counter : ""}
                      </label>

                      <p className="otp-notification">
                        {" "}
                        A verification code has been sent to your phone
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile_number_otp"
                      />
                      <p
                        style={{ display: "none" }}
                        id="otp-text-box"
                        className="text-red m-0"
                      >
                        Your otp is wrong, Please enter a correct otp
                      </p>
                    </div>
                    <button
                      // onClick={() => feedback_validate()}
                      onClick={() => onSubmitOTP()}
                      style={{ borderRadius: "100px" }}
                      type="submit"
                      className="btn-red"
                    >
                      verify
                    </button>

                    <div className="d-flex">
                      <p className="m-0">Didn't receive OTP?</p>
                      <button
                        // onClick={() => feedback_validate()}
                        onClick={async () => {
                          setChangePoint("1");
                          Resendotp();
                        }}
                        // onClick={()=>onResendOtp()}
                        type="submit"
                        style={{ color: "red", background: "transparent", border: "none" }}
                        disabled={resendtime ? true : false}
                      >
                        Re-send otp
                      </button>
                    </div>
                  </div>
                ) : ( */}
                <button
                  onClick={() => addCart(full_data.docId, full_data)}
                  style={{ borderRadius: "100px" }}
                  type="submit"
                  className="btn-red"
                >
                  Add to cart
                </button>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer className="p-3" position={"bottom-end"}>
          <Toast show={showB}>
            <Toast.Header
              style={{
                backgroundColor: "darkcyan",
                color: "white",
              }}
              onClick={toggleShowB}
              closeButton={true}
            >
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Add to cart</strong>
              <small>
                <img
                  src="https://img.freepik.com/premium-vector/hand-drawn-green-icon-red-backdrop-checkmark-right-vector-icon-hand-drawn_194782-807.jpg?w=2000"
                  height={"30px"}
                />
              </small>
            </Toast.Header>
            <Toast.Body>Your product successfully move to add cart.</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>

      <>
        <Modal
          show={modalShowBrochure}
          onHide={() => CloseModalBrochure()}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h5>
                <span className="text-underline-blue">You want </span>
                <span className="text-underline">
                  {project_name_get} brochure
                </span>
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-3 pt-0">
            <div className="right-side-form">
              <div>
                <div className="form-group">
                  <label for="yourName">Your name</label>
                  <input
                    type="email"
                    name="from_name"
                    className="form-control"
                    id="brochure_name_data"
                    aria-describedby="emailHelp"
                    disabled={settextinputbrochure ? true : false}
                  />
                  <p
                    style={{ display: "none" }}
                    id="brochure_name"
                    className="text-red m-0"
                  >
                    Enter a Name must
                  </p>
                </div>
                <div className="form-group">
                  <label for="mobileNumber">Mobile Number</label>
                  <div id="recaptcha-container"></div>
                  <div id="recaptcha-container-resend"></div>
                  <div id="recaptcha-container-resends"></div>
                  <input
                    type="email"
                    name="reply_to"
                    className="form-control"
                    id="brochure_mobile_number_data"
                    aria-describedby="emailHelp"
                    disabled={settextinputbrochure ? true : false}
                  />
                  <p
                    style={{ display: "none" }}
                    id="brochure_mobile_number"
                    className="text-red m-0"
                  >
                    Enter a mobile number must
                  </p>
                </div>
                <div className="form-group">
                  <label for="yourMail">Your mail</label>
                  <input
                    type="text"
                    className="form-control"
                    id="brochure_mail_data"
                    disabled={settextinputbrochure ? true : false}
                  />
                  <p
                    style={{ display: "none" }}
                    id="brochure_mail"
                    className="text-red m-0"
                  >
                    Enter a mail must
                  </p>
                </div>
                {settextinputbrochure ? (
                  <div>
                    <div className="form-group">
                      <label for="yourMail">
                        Enter the OTP {resendtime ? counter : ""}
                      </label>
                      <p className="otp-notification">
                        {" "}
                        A verification code has been sent to your phone
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        id="brochure_otp_data"
                      />
                      <p
                        style={{ display: "none" }}
                        id="brochure_otp"
                        className="text-red m-0"
                      >
                        Your otp is wrong, Please enter a correct otp
                      </p>
                    </div>
                    <button
                      // onClick={() => feedback_validate()}
                      onClick={() => onSubmitOTPbrochure()}
                      style={{ borderRadius: "100px" }}
                      type="submit"
                      className="btn-red"
                    >
                      verify
                    </button>

                    <div className="d-flex">
                      <p className="m-0">Didn't receive OTP?</p>
                      <button
                        // onClick={() => feedback_validate()}
                        onClick={async () => {
                          setChangePoint("2");
                          Resendotp();
                        }}
                        // onClick={()=>onResendOtp()}
                        type="submit"
                        style={{ color: "red" }}
                        disabled={resendtime ? true : false}
                      >
                        Re-send otp
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => feedback_brochure_validate()}
                    style={{ borderRadius: "100px" }}
                    type="submit"
                    className="btn-red"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
      <>
        <Modal
          show={modalShowUnit}
          onHide={() => CloseModalUnit()}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h5>
                <span className="text-underline-blue">You want </span>
                <span className="text-underline">
                  {project_name_get} {selecttype}
                </span>
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-3 pt-0">
            <div className="right-side-form">
              <div>
                <div className="form-group">
                  <label for="yourName">Your name</label>
                  <input
                    type="email"
                    className="form-control"
                    id="unit_name_data"
                    aria-describedby="emailHelp"
                  />
                  <p
                    style={{ display: "none" }}
                    id="unit_name"
                    className="text-red m-0"
                  >
                    Enter a Name must
                  </p>
                </div>
                <div className="form-group">
                  <label for="mobileNumber">Mobile Number</label>
                  <div id="recaptcha-container"></div>
                  <div id="recaptcha-container-resend"></div>
                  <div id="recaptcha-container-resends"></div>
                  <input
                    type="email"
                    className="form-control"
                    id="unit_mobile_number_data"
                    aria-describedby="emailHelp"
                  />
                  <p
                    style={{ display: "none" }}
                    id="unit_mobile_number"
                    className="text-red m-0"
                  >
                    Enter a mobile number must
                  </p>
                </div>
                <div className="form-group">
                  <label for="yourMail">Your mail</label>
                  <input
                    type="text"
                    className="form-control"
                    id="unit_mail_data"
                    value={localStorage.getItem("customerEmail")}
                    disabled
                  />
                  <p
                    style={{ display: "none" }}
                    id="unit_mail"
                    className="text-red m-0"
                  >
                    Enter a mail must
                  </p>
                </div>
                {settextinputunit ? (
                  <div>
                    <div className="form-group">
                      <label for="yourMail">
                        Enter the OTP {resendtime ? counter : ""}
                      </label>
                      <p className="otp-notification">
                        {" "}
                        A verification code has been sent to your phone
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        id="unit_otp_data"
                      />
                      <p
                        style={{ display: "none" }}
                        id="unit_otp"
                        className="text-red m-0"
                      >
                        Your otp is wrong, Please enter a correct otp
                      </p>
                    </div>
                    <button
                      // onClick={() => feedback_validate()}
                      onClick={() => onSubmitOTPUnit()}
                      style={{ borderRadius: "100px" }}
                      type="submit"
                      className="btn-red"
                    >
                      verify
                    </button>

                    <div className="d-flex">
                      <p className="m-0">Didn't receive OTP?</p>
                      <button
                        // onClick={() => feedback_validate()}
                        onClick={async () => {
                          setChangePoint("3");
                          Resendotp();
                        }}
                        // onClick={()=>onResendOtp()}
                        type="submit"
                        style={{ color: "red" }}
                        disabled={resendtime ? true : false}
                      >
                        Re-send otp
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => unit_validate()}
                    style={{ borderRadius: "100px" }}
                    type="submit"
                    className="btn-red"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
      <>
        <Modal
          show={modalShow}
          onHide={() => false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="text-center p-5">
            <img src={successpopup} style={{ width: "65px", height: "65px" }} />
            <h4 className="text-center mt-4">{project_name_get}</h4>
            <p className="text-center">
              Dear {namePass}, Thanks for your order on Seafarerschoice. Our
              Team shall Reach you shortly
            </p>
            <Button
              onClick={() => setModalShow(false)}
              className="btn-popup-sucess mt-3"
            >
              Continue
            </Button>
          </Modal.Body>
        </Modal>
      </>
      {/* Page Popup form start */}
      <>
        <Modal
          show={modalShowhome}
          onHide={() => CloseModalhome()}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h5>
                <span className="text-underline-blue">Interested in </span>
                <span className="text-underline">{project_name_get}</span>
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-3 pt-0">
            <div className="right-side-form">
              <div>
                <div className="form-group">
                  <label for="yourName">Your name</label>
                  <input
                    type="email"
                    name="from_name"
                    className="form-control"
                    id="brochure_name_data"
                    aria-describedby="emailHelp"
                    disabled={settextinputbrochure ? true : false}
                  />
                  <p
                    style={{ display: "none" }}
                    id="brochure_name"
                    className="text-red m-0"
                  >
                    Enter a Name must
                  </p>
                </div>
                <div className="form-group">
                  <label for="mobileNumber">Mobile Number</label>
                  <div id="recaptcha-container"></div>
                  <div id="recaptcha-container-resend"></div>
                  <div id="recaptcha-container-resends"></div>

                  <input
                    type="email"
                    name="reply_to"
                    className="form-control"
                    id="brochure_mobile_number_data"
                    aria-describedby="emailHelp"
                    disabled={settextinputbrochure ? true : false}
                  />
                  <p
                    style={{ display: "none" }}
                    id="brochure_mobile_number"
                    className="text-red m-0"
                  >
                    Enter a mobile number must
                  </p>
                </div>
                <div className="form-group">
                  <label for="yourMail">Your mail</label>
                  <input
                    type="text"
                    className="form-control"
                    id="brochure_mail_data"
                    value={localStorage.getItem("customerEmail")}
                    disabled
                  />
                  <p
                    style={{ display: "none" }}
                    id="brochure_mail"
                    className="text-red m-0"
                  >
                    Enter a mail must
                  </p>
                </div>
                <div className="form-group">
                  <label for="yourMail">Address</label>

                  <input
                    type="text"
                    className="form-control"
                    id="intrested_data"
                    onChange={(e) => setPlaceHolder(e.target.value)}
                  />
                  <p
                    style={{ display: "none" }}
                    id="intrest"
                    className="text-red m-0"
                  >
                    Enter a message must
                  </p>
                </div>
                {settextinputbrochure ? (
                  <div>
                    <div className="form-group">
                      <label for="yourMail">
                        Enter the OTP {resendtime ? counter : ""}
                      </label>
                      <p className="otp-notification">
                        {" "}
                        A verification code has been sent to your phone
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        id="brochure_otp_data"
                      />
                      <p
                        style={{ display: "none" }}
                        id="brochure_otp"
                        className="text-red m-0"
                      >
                        Your otp is wrong, Please enter a correct otp
                      </p>
                    </div>
                    <button
                      // onClick={() => feedback_validate()}
                      onClick={() => home_onSubmitOTP()}
                      style={{ borderRadius: "100px" }}
                      type="submit"
                      className="btn-red"
                    >
                      verify
                    </button>
                    <div className="d-flex">
                      <p className="m-0">Didn't receive OTP?</p>
                      <button
                        // onClick={() => feedback_validate()}
                        onClick={async () => {
                          setChangePoint("2");
                          Resendotp();
                        }}
                        // onClick={()=>onResendOtp()}
                        type="submit"
                        style={{ color: "red" }}
                        disabled={resendtime ? true : false}
                      >
                        Re-send otp
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => home_feedback()}
                    // onSignInSubmitbrochure
                    style={{ borderRadius: "100px" }}
                    type="submit"
                    className="btn-red"
                  >
                    Buy
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
      {/* Page Popup form end */}
      <>
        <MyVerticallyCenteredVideo
          show={modalShowVideo}
          onHide={() => setModalShowVideo(false)}
        />
      </>

      <div className="div">
        <div className="container" style={{ border: "none" }}>
          <div className="row">
            <div className="col-sm-8">
              {/* <div className="btn_div"> */}

              <div className="amenities"></div>
              <div style={{ marginTop: "3%" }}></div>
              <div></div>
            </div>
            <div className="col-sm-4 "></div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main function end
export default ReadProjects;
