import React from "react";
import SailorsPrivacy from "../images/sailors-privacy.png";

export default function TermsAndConditions() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-3"></div>
        <div className="col-2">
          <img src={SailorsPrivacy} height="100px"></img>
        </div>
        <div className="col-6 mt-5">
          <h3 style={{ color: "red" }}>TERMS & CONDITIONS</h3>
        </div>
      </div>
      <p>Last updated: May 18, 2023</p>
      <p className="mt-5">
        These Terms and Conditions govern the use of the services provided by
        thesailorschoice(Seafarerschoice), a private limited entity registered
        under the Ministry of Corporate Affairs in India. By accessing or using
        our services, you agree to be bound by these terms and conditions.
      </p>
      <strong>
        <h6 className="mt-3">1. Service Description</h6>
      </strong>
      <p>
        Seafarerschoice provides a platform that connects shippers with
        experienced sailors for fulfilling their shipping needs. Our services
        include facilitating communication, arranging logistics, and ensuring
        smooth operations for efficient shipping.
      </p>
      <strong>
        <h6 className="mt-3">2. User Responsibilities</h6>
      </strong>
      <p>
        2.1 Shippers: As a shipper, you are responsible for providing accurate
        and complete information about your shipping requirements. You agree to
        comply with all applicable laws and regulations related to shipping and
        transportation.
      </p>
      <p>
        2.2 Sailors: As a sailor, you must provide accurate and up-to-date
        information about your skills, qualifications, and availability. You
        agree to comply with all applicable laws and regulations related to
        shipping and adhere to professional standards.
      </p>
      <h6 className="mt-4">3. Booking and Payment</h6>
      <p className="mt-3">
        3.1 Shippers can submit booking requests through our platform,
        specifying their shipping requirements. Sailors can review these
        requests and accept or decline them based on their availability and
        suitability for the job.
      </p>
      <p>
        3.2 Once a booking is confirmed between a shipper and a sailor, the
        agreed-upon payment terms and conditions apply. The payment terms may
        vary based on the nature of the shipping assignment and shall be agreed
        upon by both parties.
      </p>
      <h6 className="mt-4">4. Intellectual Property</h6>
      <p>
        All content and materials provided on the Seafarerschoice platform,
        including but not limited to logos, trademarks, text, graphics, and
        software, are the property of the Company. Users are prohibited from
        reproducing, distributing, or modifying any content without prior
        written consent from the Company.
      </p>
      <h6 className="mt-4">4. Intellectual Property</h6>
      <p>
        All content and materials provided on the Seafarerschoice platform,
        including but not limited to logos, trademarks, text, graphics, and
        software, are the property of the Company. Users are prohibited from
        reproducing, distributing, or modifying any content without prior
        written consent from the Company.
      </p>
      <h6 className="mt-4">5. Confidentiality</h6>
      <p>
        Both shippers and sailors acknowledge and agree to maintain the
        confidentiality of any sensitive information shared during the course of
        their engagement. This includes, but is not limited to, trade secrets,
        proprietary information, and personal data. The Company will handle user
        data in accordance with its Privacy Policy.
      </p>
      <h6 className="mt-4">5. Governing Law and Jurisdiction</h6>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of India. Any disputes arising out of or in connection with this
        Agreement shall be subject to the exclusive jurisdiction of the courts
        located in Chennai
      </p>
    </div>
  );
}
