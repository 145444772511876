import { React, useEffect, useState } from "react";
import "./css/login.css";
import { useNavigate, Outlet, Link } from "react-router-dom";
import db from "./firebaseConfig";
import firebase from "firebase";
import { auth } from "./firebaseConfig";

function SignIn() {
  let navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  function login() {
    try {
      const res = auth
        .signInWithEmailAndPassword(email, pass)
        .then((response) => {
          console.log("login success", response.user.email);
          if (response.user !== "") {
            console.log("call success");
            // auth.login(response.user.email);
            localStorage.setItem("login", "true");
            localStorage.setItem("email", email);
            // naviga(
            //   `/dashboard`)
            db.collection("Users")
              .where("userEmail", "==", email)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  let data = doc.data();
                  localStorage.setItem("customerNumber", data.userMob);
                  localStorage.setItem("customerEmail", data.userEmail);
                  localStorage.setItem("customerId", data.customerId);
                  setTimeout(() => {
                    window.location.assign("/home");
                  }, 1000);
                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });
          } else {
            // setShow(true)
            console.log("call failure");
          }
        })
        .catch((err) => {
          console.log("err", err);
          // setShow(true);
        });
    } catch (error) {}
  }
  return (
    <div>
      <div>
        <div class="container mt-5">
          <div class="row justify-content-md-center">
            <div class="col-md-auto singIn-box">
              <h4 className="text-center">Sign In</h4>
              <div>
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    onChange={(e) => setEmail(e.target.value)}
                    aria-describedby="emailHelp"
                  />
                  <div id="emailHelp" class="form-text">
                    We'll never share your email with anyone else.
                  </div>
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    onChange={(e) => setPass(e.target.value)}
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3 form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                  />
                  <label class="form-check-label" for="exampleCheck1">
                    Please agree the{" "}
                    <a href="/privacy-policy">terms & conditions</a>
                  </label>
                  <Link to="/sign-up">Signup</Link>
                </div>
                <button onClick={() => login()} class="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
